import React, { useEffect, useState } from "react";
import { Link, useNavigate } from "react-router-dom";
import HomeNav from "../Components/HomeNav";
import HomeFooter from "../Components/HomeFooter";
import passwordEye from "../Icons/eye.svg";
import passwordEyeSlash from "../Icons/eye-slash.svg";
import { useContext, useRef } from "react";
import axios from "../api/axios";
import { Context } from "../Context/Context";
import "../CSS/Login.css";
import excl from "../Icons/exclamation-lg.svg";

const StudentLogin = () => {
  useEffect(() => {
    document.title = "Student Login";
  }, []);

  const [email, setEmail] = useState();
  const [password, setPassword] = useState();
  const type = "student";
  const [passwordShow, setPasswordShow] = useState(false);
  const [loginFail, setLoginFail] = useState(false);
  const [success, setSucess] = useState(false);
  const { dispatch, isFetching } = useContext(Context);
  const [loading, setLoading] = useState(false);
  const [errMessage, setErrMessage] = useState();

  const navigate = useNavigate()

  const toggleShow = () => {
    setPasswordShow(!passwordShow);
  };

  const handleSubmit = async (e) => {
    e.preventDefault();
    setLoading(true);
    setLoginFail(false);
    dispatch({ type: "LOGIN_START" });
    try {
      const res = await axios.post(
        `/api/auth/login`,
        JSON.stringify({
          email,
          password,
          type
        }),
        {
          headers: { "Content-type": "application/json" }
        }
      );
      dispatch({ type: "LOGIN_SUCCESS", payload: res.data });
      setLoading(false);
      res.data && navigate("/students/index");
    } catch (err) {
      dispatch({ type: "LOGIN_FAILURE" });
      setLoginFail(true);
      setLoading(false);
      err.response.data.errors
        ? setErrMessage(err.response.data.errors)
        : setErrMessage(err.response.data.message);
    }
  };

  return (
    <div>
      <HomeNav />
      <div className="content-container1 middle page">
        <div className="form-container">
          <form>
            <div className="input-section">
              <h1 className="main-header">Student</h1>
              {/* <h3 className="sub-header login-text">Login</h3> */}

              <input
                type="text"
                name="email"
                id="email"
                placeholder="Email"
                onChange={(e) => setEmail(e.target.value)}
                required
                className="login-form-input"
              />
              <div className="password-container">
                <input
                  type={passwordShow ? "text" : "password"}
                  name="password"
                  id="password"
                  placeholder="Password"
                  onChange={(e) => setPassword(e.target.value)}
                  required
                  className="login-form-input"
                />
                <img
                  src={passwordShow ? `${passwordEye}` : `${passwordEyeSlash}`}
                  alt=""
                  className="eye"
                  onClick={toggleShow}
                />
              </div>
              <button className="login-btn" onClick={handleSubmit}>
                {loading ? (
                  <div className="center-load">
                    <span className="basic"></span>
                  </div>
                ) : (
                  `Login`
                )}
              </button>
              <div className={loginFail ? "post-fail" : ""}>
                {loginFail ? (
                  <div className="result-icon">
                    <img src={excl} />
                    {errMessage}
                  </div>
                ) : (
                  ""
                )}
              </div>
            </div>
          </form>
          <p className="obj-description">
            No Account?{" "}
            <Link to="/verifyStudent" className="register-link">
              Register Here
            </Link>
          </p>
          <p className="obj-description">
            Forgot Password?{" "}
            
            <Link to="/VerifyStudentPass" className="register-link">
            Click Here
            </Link>
          </p>
        {/* <HomeFooter /> */}
      </div>
        </div>
    </div>
  );
};

export default StudentLogin;
