import React, { useEffect, useState } from "react";
import { useNavigate } from "react-router-dom";
import axios from "../../api/axios";
import CoordinatorNav from "../../Components/CoordinatorNav";
import excl from "../../Icons/exclamation-lg.svg";
import tick from "../../Icons/check-lg.svg";
import "../../CSS/Profile.css";

const UploadForm = () => {
  useEffect(() => {
    document.title = "Form Upload";
  }, []);

  const [form, setForm] = useState();
  const [description, setDescription] = useState();
  const [purpose, setPurpose] = useState();
  const [delId, setDelId] = useState();
  const [success, setSucess] = useState(false);
  const [delSuccess, setDelSucess] = useState(false);
  const [delFail, setDelFail] = useState(false);
  const [regFail, setRegFail] = useState(false);
  const [loading, setLoading] = useState(false);
  const [delLoading, setDelLoading] = useState(false);
  const [formsLoading, setFormsLoading] = useState(true);
  const [loadFail, setLoadFail] = useState(false);
  const [errMessage, setErrMessage] = useState();
  const [delErrMessage, setDelErrMessage] = useState();
  const [formErrMessage, setFormErrMessage] = useState();
  const [reload, setReload] = useState(false);
  const [formInfo, setFormInfo] = useState();
  const navigate = useNavigate();

  const formData = new FormData();
  formData.append("purpose", purpose);
  formData.append("form", form);
  formData.append("description", description);

  useEffect(() => {
    setLoadFail(false);
    const fetchData = async () => {
      try {
        const res = await axios.get(`/api/coordinators/forms`);
        setFormInfo(res.data);
        setFormsLoading(false);
      } catch (error) {
        setLoadFail(true);
        setFormsLoading(false);
        setFormErrMessage(error.response.data.message);
      }
    };
    fetchData();
  }, [reload]);

  const handleSubmit = async (e) => {
    e.preventDefault();
    setLoading(true);
    setRegFail(false);
    try {
      const res = await axios.post(
        `/api/coordinators/uploadInspectionForms`,
        formData,
        {
          headers: { "content-type": "multipart/form-data" }
        }
      );
      setSucess(true);
      setLoading(false);
      setRegFail(false);
      setTimeout(() => {
        navigate(0);
      }, 2000);
    } catch (err) {
      setRegFail(true);
      setLoading(false);
      err.response.data.errors
        ? setErrMessage(err.response.data.errors)
        : setErrMessage(err.response.data.message);
    }
  };

  const handleDelete = async (id, publicId) => {
    setDelLoading(true);
    setDelFail(false);
    try {
      const res = await axios.delete(
        `/api/coordinators/deleteForm?formId=${id}&publicId=${publicId}`
      );
      setDelSucess(true);
      setDelLoading(false);
      setDelFail(false);
      setTimeout(() => {
        setDelSucess(false);
      }, 3000);
      setReload(!reload);
    } catch (err) {
      setDelFail(true);
      setDelLoading(false);
      err.response.data.errors
        ? setDelErrMessage(err.response.data.errors)
        : setDelErrMessage(err.response.data.message);
    }
  };

  return (
    <div>
      <CoordinatorNav />
      <div className="page-wrapper">
        <div className="page-content">
          <div className="heading">
            <h2 className="profile-header">Upload Defence/Inspection Forms</h2>
          </div>

          <div className="work-form-wrapper">
            <form action="" method="post" className="report-form">
              <label htmlFor="description">
                <span className="label">Brief Description</span>
                <textarea
                  name="description"
                  id="description"
                  cols="20"
                  rows="2"
                  className="work-form-input"
                  required
                  onChange={(e) => setDescription(e.target.value)}
                ></textarea>
              </label>
              <label htmlFor="form_purpose">
                <span className="label form-purpose-cont">Form Purpose</span>
                <select
                  name="purpose"
                  id="purpose"
                  className="form-purpose"
                  onChange={(e) => setPurpose(e.target.value)}
                >
                  <option value=""></option>
                  <option value="Defence">Defence</option>
                  <option value="Inspection">Inspection</option>
                </select>
              </label>
              <label htmlFor="upload_file">
                <span className="label">Upload File</span>
                <input
                  type="file"
                  name="upload_file"
                  id="upload_file"
                  className="work-form-input upload"
                  onChange={(e) => setForm(e.target.files[0])}
                />
              </label>
              <button className="submit-upload" onClick={handleSubmit}>
                {loading ? (
                  <div className="center-load">
                    <span className="basic"></span>
                  </div>
                ) : (
                  `Upload Form`
                )}
              </button>
              <div className={regFail ? "post-fail" : ""}>
                {regFail ? (
                  <div className="result-icon">
                    <img src={excl} />
                    {errMessage}
                  </div>
                ) : (
                  ""
                )}
              </div>
              <div className={success ? "post-success" : ""}>
                {success ? (
                  <div className="result-icon">
                    <img src={tick} />
                    Form Uploaded Successfully!
                  </div>
                ) : (
                  ""
                )}
              </div>
            </form>
          </div>
          <div className="heading">
            <h2 className="profile-header">Uploaded Forms</h2>
          </div>
          <>
            {formsLoading ? (
              <div className="center-load">
                <span className="basic big"></span>
              </div>
            ) : loadFail ? (
              <h2 className="profile-header">{formErrMessage}</h2>
            ) : (
              <div className="loaded">
                <div className="limiter">
                  <div className="container-table100">
                    <div className="wrap-table100">
                      <div className="table100">
                        <table>
                          <thead>
                            <tr className="table100-head">
                              <th className="column1">Form Type</th>
                              <th className="column2">Form Description</th>
                              <th className="column3">Form Document</th>
                              <th className="column3">Delete Form</th>
                            </tr>
                          </thead>
                          <tbody>
                            {formInfo.map((form) => {
                              return (
                                <tr key={form._id}>
                                  <td className="column1 capitalize">
                                    {form.purpose}
                                  </td>
                                  <td className="column2">
                                    {form.description}
                                  </td>
                                  <td className="column3">
                                    <p className="reg">{form.name}</p>
                                  </td>
                                  <td className="column3">
                                    <button
                                      className="submit-upload"
                                      onClick={() => {
                                        setDelId(form._id);
                                        handleDelete(form._id, form.publicId);
                                      }}
                                    >
                                      {form._id === delId && delLoading ? (
                                        <div className="center-load">
                                          <span className="basic"></span>
                                        </div>
                                      ) : (
                                        `Delete`
                                      )}
                                    </button>
                                    <div
                                      className={
                                        form._id === delId && delFail
                                          ? "post-fail"
                                          : ""
                                      }
                                    >
                                      {form._id === delId && delFail ? (
                                        <div className="result-icon">
                                          {delErrMessage}
                                        </div>
                                      ) : (
                                        ""
                                      )}
                                    </div>
                                    <div
                                      className={
                                        form._id === delId && delSuccess
                                          ? "post-success"
                                          : ""
                                      }
                                    >
                                      {form._id === delId && delSuccess ? (
                                        <div className="result-icon">
                                          Deleted Successfully!
                                        </div>
                                      ) : (
                                        ""
                                      )}
                                    </div>
                                  </td>
                                </tr>
                              );
                            })}
                          </tbody>
                        </table>
                      </div>
                    </div>
                  </div>
                </div>
              </div>
            )}
          </>
        </div>
      </div>
    </div>
  );
};

export default UploadForm;
