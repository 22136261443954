import React, { useEffect, useState } from "react";
import { Link } from "react-router-dom";
import axios from "../../api/axios";
import CoordinatorNav from "../../Components/CoordinatorNav";
import csvIcon from "../../Icons/filetype-csv.svg";
import "../../CSS/Profile.css";

const CollateGrades = () => {
  useEffect(() => {
    document.title = "Batch Grading";
  }, []);

  const [loading, setLoading] = useState(true);
  const [submitLoading, setSubmitLoading] = useState(false);
  const [loadFail, setLoadFail] = useState(false);
  const [errMessage, setErrMessage] = useState();
  const [studentData, setStudentData] = useState();
  const [success, setSucess] = useState(false);
  const [regFail, setRegFail] = useState(false);
  const [csvData, setCsvData] = useState([]);
  const [studentGrades, setStudentGrades] = useState([]);

  useEffect(() => {
    setLoadFail(false);
    const fetchData = async () => {
      try {
        const res = await axios.get(`/api/coordinators/students`);
        setStudentData(res.data.students);

        setLoading(false);
        setLoadFail(false);
      } catch (error) {
        setLoadFail(true);
        setLoading(false);
        setErrMessage(error.response.data.message);
      }
    };
    fetchData();
  }, []);

  const handleGradeChange = (event, index, id) => {
    const { name, value } = event.target;

    setStudentGrades((prevStudents) => {
      const newStudents = [...prevStudents];

      newStudents[index] = {
        ...newStudents[index],
        id,
        [name]: value,
      };

      return newStudents;
    });
  };

  const handleSubmit = async (e) => {
    e.preventDefault();
    // setSubmitLoading(true);
    // setRegFail(false);
    // try {
    //   const res = await axios.patch(`/api/coordinators/collateAllGrades`, {
    //     headers: { "Content-type": "application/json" },
    //   });
    //   setSucess(true);
    //   setSubmitLoading(false);
    //   setRegFail(false);
    //   setTimeout(() => {
    //     setSucess(false);
    //   }, 3000);
    // } catch (err) {
    //   setRegFail(true);
    //   setSubmitLoading(false);
    //   err.response.data.errors
    //     ? setErrMessage(err.response.data.errors)
    //     : setErrMessage(err.response.data.message);
    // }
  };

  return (
    <div>
      <CoordinatorNav />
      <div className="page-wrapper">
        <div className="page-content">
          <div className="heading">
            <h2 className="profile-header">Batch Grading</h2>
          </div>
          {loading ? (
            <div className="center-load">
              <span className="basic big"></span>
            </div>
          ) : loadFail ? (
            <h2 className="profile-header">{errMessage}</h2>
          ) : (
            <div className="limiter loaded">
              <div className="container-table100">
                <div className="wrap-table100">
                  <div className="table100">
                    <table className="co-students">
                      <thead>
                        <tr className="table100-head">
                          <th className="column4">Student Name</th>
                          <th className="column5">Matric Number</th>
                          <th className="column4">Course</th>
                          <th className="column4">Weekly Report Grade</th>
                          <th className="column4">Inspection Grade</th>
                          <th className="column4">Defence Grade</th>
                        </tr>
                      </thead>
                      <tbody>
                        {studentData.map((student, index) => {
                          return (
                            <tr key={student._id}>
                              <td className="column6 capitalize">
                                <Link
                                  to={`/coordinator/student/${student._id}`}
                                  className="reg"
                                >{`${student.lastName} ${student.firstName} ${
                                  student.middleName ? student.middleName : ""
                                }`}</Link>
                              </td>
                              <td className="column5">{student.matricNo}</td>
                              <td className="column4 capitalize">
                                {student.course}
                              </td>
                              <td className="column4">
                                <input
                                  type="number"
                                  name="weeklyReportGrade"
                                  className="batch-grade-input"
                                  onChange={(e) => {
                                    handleGradeChange(e, index, student._id);
                                  }}
                                />
                              </td>
                              <td className="column4">
                                <input
                                  type="number"
                                  className="batch-grade-input"
                                  name="inspectionGrade"
                                  onChange={(e) => {
                                    handleGradeChange(e, index, student._id);
                                  }}
                                />
                              </td>
                              <td className="column4">
                                <input
                                  type="number"
                                  name="defenceGrade"
                                  className="batch-grade-input"
                                  onChange={(e) => {
                                    handleGradeChange(e, index, student._id);
                                  }}
                                />
                              </td>
                            </tr>
                          );
                        })}
                      </tbody>
                    </table>
                    <button className="submit-upload" onClick={handleSubmit}>
                      {submitLoading ? (
                        <div className="center-load">
                          <span className="basic"></span>
                        </div>
                      ) : (
                        `Grade Students`
                      )}
                    </button>
                    <div className={regFail ? "post-fail" : ""}>
                      {regFail ? (
                        <div className="result-icon">{errMessage}</div>
                      ) : (
                        ""
                      )}
                    </div>
                    <div className={success ? "post-success" : ""}>
                      {success ? (
                        <div className="result-icon">Graded Successfully!</div>
                      ) : (
                        ""
                      )}
                    </div>
                  </div>
                </div>
              </div>
            </div>
          )}
        </div>
      </div>
    </div>
  );
};

export default CollateGrades;
