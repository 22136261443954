import React, { useEffect, useState } from "react";
import { useNavigate } from "react-router-dom";
import CoordinatorNav from "../../Components/CoordinatorNav";
import excl from "../../Icons/exclamation-lg.svg";
import tick from "../../Icons/check-lg.svg";
import axios from "../../api/axios";
import "../../CSS/Profile.css";

const SetRegistrationDeadline = () => {
  const [time, setTime] = useState();
  const [errMessage, setErrMessage] = useState();
  const [uploadFail, setUploadFail] = useState();
  const [success, setSucess] = useState(false);
  const [loading, setLoading] = useState(false);
  const navigate = useNavigate()

  const handleSubmit = async (e) => {
    e.preventDefault();
    setUploadFail(false);
    setLoading(true);
    try {
      const res = await axios.post(
        `/api/coordinators/setRegistrationDeadline`,
        JSON.stringify({ time }),
        {
          headers: { "Content-type": "application/json" }
        }
      );
      setSucess(true);
      setLoading(false);
      setUploadFail(false);
      setTimeout(() => {
        navigate("/coordinator/index");
      }, 3000);
    } catch (err) {
      setLoading(false);
      setUploadFail(true);
      setErrMessage(err.response.data.message);
    }
  };

  useEffect(() => {
    document.title = "Set Registration Deadline";
  }, []);
  return (
    <div>
      <CoordinatorNav />
      <div className="page-wrapper">
        <div className="page-content">
          <div className="heading">
            <h2 className="profile-header">Set Registration Deadline</h2>
          </div>

          <div className="work-form-wrapper">
            <form method="post" className="report-form">
              <div className="report-form">

                <label htmlFor="deadline">
                  <span className="label">Set Deadline</span>
                    <input
                      type="datetime-local"
                      name="deadline"
                      id="deadline"
                      className="work-form-input"
                      onChange={(e) => setTime(e.target.value)}
                    />
                </label>
              </div>
              <button className="submit-upload" onClick={handleSubmit}>
                {loading ? (
                  <div className="center-load">
                    <span className="basic"></span>
                  </div>
                ) : (
                  `Set Deadline`
                )}
              </button>
              <div className={uploadFail ? "post-fail" : ""}>
                {uploadFail ? (
                  <div className="result-icon">
                    <img src={excl} />
                    {errMessage}
                  </div>
                ) : (
                  ""
                )}
              </div>
              <div className={success ? "post-success" : ""}>
                {success ? (
                  <div className="result-icon">
                    <img src={tick} />
                    Deadline Set Successfully!
                  </div>
                ) : (
                  ""
                )}
              </div>
            </form>
          </div>
        </div>
      </div>
    </div>
  );
};

export default SetRegistrationDeadline;
