import { createContext, useReducer, useEffect } from "react";
import { Reducer, ReducerCoordinator, ReducerSupervisor, ReducerVerification} from "./Reducer";

const INITIAL_STATE = {
  student: JSON.parse(localStorage.getItem("student")) || null,
  isFetching: false,
  error: false
};

const INITIAL_STATE_COORDINATOR = {
  coordinator: JSON.parse(localStorage.getItem("coordinator")) || null,
  isFetching: false,
  error: false
};

const INITIAL_STATE_SUPERVISOR = {
  supervisor: JSON.parse(localStorage.getItem("supervisor")) || null,
  isFetching: false,
  error: false
};
const INITIAL_STATE_VERIFICATION = {
  verification: JSON.parse(localStorage.getItem("verification")) || null,
  isFetching: false,
  error: false
};
const INITIAL_STATE_VALIDATION = {
  validation: JSON.parse(localStorage.getItem("validation")) || null,
  isFetching: false,
  error: false
};

export const Context = createContext(INITIAL_STATE);
export const ContextCoordinator = createContext(INITIAL_STATE_COORDINATOR);
export const ContextSupervisor = createContext(INITIAL_STATE_SUPERVISOR);
export const ContextVerification = createContext(INITIAL_STATE_VERIFICATION);
export const ContextValidation = createContext(INITIAL_STATE_VALIDATION);
// export const ContextValidation = createContext(INITIAL_STATE_VALIDATION);

export const ContextProvider = ({ children }) => {
  const [state, dispatch] = useReducer(Reducer, INITIAL_STATE);

  useEffect(() => {
    localStorage.setItem("student", JSON.stringify(state.student));
  }, [state.student]);
  return (
    <Context.Provider
      value={{
        student: state.student,
        isFetching: state.isFetching,
        error: state.error,
        dispatch
      }}
    >
      {children}
    </Context.Provider>
  );
};
export const ContextProviderCoordinator = ({ children }) => {
  const [state, dispatch] = useReducer(
    ReducerCoordinator,
    INITIAL_STATE_COORDINATOR
  );

  useEffect(() => {
    localStorage.setItem("coordinator", JSON.stringify(state.coordinator));
  }, [state.coordinator]);
  return (
    <ContextCoordinator.Provider
      value={{
        coordinator: state.coordinator,
        isFetching: state.isFetching,
        error: state.error,
        dispatch
      }}
    >
      {children}
    </ContextCoordinator.Provider>
  );
};

export const ContextProviderSupervisor = ({ children }) => {
  const [state, dispatch] = useReducer(
    ReducerSupervisor,
    INITIAL_STATE_SUPERVISOR
  );

  useEffect(() => {
    localStorage.setItem("supervisor", JSON.stringify(state.supervisor));
  }, [state.supervisor]);
  return (
    <ContextSupervisor.Provider
      value={{
        supervisor: state.supervisor,
        isFetching: state.isFetching,
        error: state.error,
        dispatch
      }}
    >
      {children}
    </ContextSupervisor.Provider>
  );
};
export const ContextProviderVerification = ({ children }) => {
  const [state, dispatch] = useReducer(
    ReducerVerification,
    INITIAL_STATE_VERIFICATION
  );

  useEffect(() => {
    localStorage.setItem("verification", JSON.stringify(state.verification));
  }, [state.verification]);
  return (
    <ContextVerification.Provider
      value={{
        verification: state.verification,
        isFetching: state.isFetching,
        error: state.error,
        dispatch
      }}
    >
      {children}
    </ContextVerification.Provider>
  );
};
// export const ContextProviderValidation = ({ children }) => {
//   const [state, dispatch] = useReducer(
//     ReducerValidation,
//     INITIAL_STATE_VALIDATION
//   );

//   useEffect(() => {
//     localStorage.setItem("validation", JSON.stringify(state.validation));
//   }, [state.validation]);
//   return (
//     <ContextValidation.Provider
//       value={{
//         validation: state.validation,
//         isFetching: state.isFetching,
//         error: state.error,
//         dispatch
//       }}
//     >
//       {children}
//     </ContextValidation.Provider>
//   );
// };
