import React, { useEffect } from "react";
import ProfileNav from "../../Components/ProfileNav";
import "../../CSS/Profile.css";

const Contact = () => {
  useEffect(() => {
    document.title = "Contact";
  }, []);
  return (
    <div>
      <ProfileNav />
      <div className="page-wrapper">
        <div className="page-content">
          <div className="heading">
            <h2 className="profile-header">Coordinator's Contact Information</h2>
          </div>

          <div className="report-form1">
            <div className="details-content">
              <p className="details-title">Coordinator's Name</p>
              <p className="details-name">Dr. Izang Aaron</p>
            </div>

            <div className="details-content">
              <p className="details-title">Email Address</p>
              <p className="details-name">
                <a
                  className="details-name-link"
                  href="mailto:izanga@babcock.edu.ng"
                >
                  izanga@babcock.edu.ng
                </a>
              </p>
            </div>

            <div className="details-content">
              <p className="details-title">Office Location</p>
              <p className="details-name">SAT E-202</p>
            </div>

            <div className="details-content">
              <p className="details-title">Phone Number</p>
              <p className="details-name">08038692651, 07080821031</p>
            </div>
          </div>
        </div>
      </div>
    </div>
  );
};

export default Contact;
