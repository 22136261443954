import React, { useEffect, useState } from "react";
import { Link, useNavigate } from "react-router-dom";
import HomeNav from "../Components/HomeNav";
import HomeFooter from "../Components/HomeFooter";
import { useContext, useRef } from "react";
import axios from "../api/axios";
import { Context } from "../Context/Context";
import "../CSS/Login.css";

const StudentLogin = () => {
  useEffect(() => {
    document.title = "Validate OTP";
  }, []);
  
  const email = sessionStorage.getItem('userEmail');
  const [token, setToken] = useState()
  const [loginFail, setLoginFail] = useState(false);
  const [success, setSucess] = useState(false);
  const { dispatch, isFetching } = useContext(Context);
  const [loading, setLoading] = useState(false);
  const [errMessage, setErrMessage] = useState();

  const navigate = useNavigate()


  const handleSubmit = async (e) => {
    e.preventDefault();
    setLoading(true);
    setLoginFail(false);
    dispatch({ type: "VALIDATION_START"});
    try {
      const res = await axios.post(
        `/api/auth/verifyOTP`,
        JSON.stringify({
          token,
          email
        }),
        {
          headers: { "Content-type": "application/json" }
        }
      );
      dispatch({ type: "VALIDATION_SUCCESS",payload: res.data});
      setLoading(false);

      // set the verification in local storage
      localStorage.setItem('verification', JSON.stringify(res.data));

      res.data && window.location.assign('/Register');
    } catch (err) {
      dispatch({ type: "VALIDATION_FAILURE"});
      setLoginFail(true);
      setLoading(false);
      err.response.data.errors
        ? setErrMessage(err.response.data.errors)
        : setErrMessage(err.response.data.message);
    }
  };

  return (
    <div>
      <HomeNav />
      <div className="content-container1 page">
        <div className="form-container">
          <form>
            <div className="input-section">
              <h1 className="main-header">Validate OTP</h1>
              <h4 className="sub-header">An OTP has been sent to: {email}</h4>

              <input
                type="text"
                name="token"
                id="token"
                placeholder="OTP"
                onChange={(e) => setToken(e.target.value)}
                required
                className="login-form-input"
              />
              <button className="login-btn" onClick={handleSubmit}>
                {loading ? (
                  <div className="center-load">
                    <span className="basic"></span>
                  </div>
                ) : (
                  `Validate`
                )}
              </button>
              <div className={loginFail ? "post-fail" : ""}>
                {loginFail ? (
                  <div className="result-icon">
                    {errMessage}
                  </div>
                ) : (
                  ""
                )}
              </div>
            </div>
          </form>
        </div>
        {/* <HomeFooter /> */}
      </div>
    </div>
  );
};

export default StudentLogin;
