import React, { useEffect, useState } from "react";
import CoordinatorNav from "../../Components/CoordinatorNav";
import axios from "../../api/axios";
import "../../CSS/Profile.css";

const RegisteredSupervisors = () => {
  useEffect(() => {
    document.title = "Registered Supervisors";
  }, []);

  const [loading, setLoading] = useState(true);
  const [loadFail, setLoadFail] = useState(false);
  const [errMessage, setErrMessage] = useState();
  const [supervisorData, setSupervisorData] = useState();

  useEffect(() => {
    setLoadFail(false);
    const fetchData = async () => {
      try {
        const res = await axios.get(`/api/coordinators/supervisors`);
        setSupervisorData(res.data);
        setLoading(false);
      } catch (error) {
        setLoadFail(true);
        setLoading(false);
        setErrMessage(error.response.data.message);
      }
    };
    fetchData();
  }, []);

  return (
    <div>
      <CoordinatorNav />
      <div className="page-wrapper">
        <div className="page-content">
          <div className="heading">
            <h2 className="profile-header">Registered Supervisors</h2>
          </div>
          {loading ? (
            <div className="center-load">
              <span className="basic big"></span>
            </div>
          ) : loadFail ? (
            <h2 className="profile-header">{errMessage}</h2>
          ) : (
            <div className="limiter">
              <div className="container-table100">
                <div className="wrap-table100">
                  <div className="table100">
                    <table className="reg-supervisors">
                      <thead>
                        <tr className="table100-head">
                          <th className="column5 def">Supervisor's Name</th>
                          <th className="column5 def">Phone Number</th>
                          <th className="column5 def">Supervior's Email</th>
                          <th className="column5 def">Number of students assigned</th>
                        </tr>
                      </thead>
                      <tbody>
                        {supervisorData.map((supervisor) => {
                          return (
                            <tr key={supervisor._id}>
                              <td className="column6 def capitalize">
                                {supervisor.firstName} {supervisor.lastName}
                              </td>
                              <td className="column3 def">
                                {supervisor.phone}
                              </td>
                              <td className="column5 def">
                                {supervisor.email}
                              </td>
                              <td className="column5 def">
                                {supervisor.noOfAssignedStudents}
                              </td>
                            </tr>
                          );
                        })}
                      </tbody>
                    </table>
                  </div>
                </div>
              </div>
            </div>
          )}
        </div>
      </div>
    </div>
  );
};

export default RegisteredSupervisors;
