import React, { useEffect, useState } from "react";
import { useNavigate } from "react-router-dom";
import ProfileNav from "../../Components/ProfileNav";
import passwordEye from "../../Icons/eye.svg";
import passwordEyeSlash from "../../Icons/eye-slash.svg";
import excl from "../../Icons/exclamation-lg.svg";
import tick from "../../Icons/check-lg.svg";
import axios from "../../api/axios";
import "../../CSS/Profile.css";

const ChangePassword = () => {
  const [oldPassword, setOldPassword] = useState();
  const [newPassword, setNewPassword] = useState();
  const [errMessage, setErrMessage] = useState();
  const [uploadFail, setUploadFail] = useState();
  const [success, setSucess] = useState(false);
  const [loading, setLoading] = useState(false);
  const [passwordShow, setPasswordShow] = useState(false);
  const [passwordShow2, setPasswordShow2] = useState(false);
  const navigate = useNavigate();

  const toggleShow = () => {
    setPasswordShow(!passwordShow);
  };
  const toggleShow2 = () => {
    setPasswordShow2(!passwordShow2);
  };

  const handleSubmit = async (e) => {
    e.preventDefault();
    setUploadFail(false);
    setLoading(true);
    try {
      const res = await axios.patch(
        `/api/student/changePassword`,
        JSON.stringify({ oldPassword, newPassword }),
        {
          headers: { "Content-type": "application/json" }
        }
      );
      setSucess(true);
      setLoading(false);
      setUploadFail(false);
      setTimeout(() => {
        navigate("/students/index");
      }, 2000);
    } catch (err) {
      setLoading(false);
      setUploadFail(true);
      setErrMessage(err.response.data.message);
    }
  };

  useEffect(() => {
    document.title = "Change Password";
  }, []);
  return (
    <div>
      <ProfileNav />
      <div className="page-wrapper">
        <div className="page-content">
          <div className="heading">
            <h2 className="profile-header">Change Password</h2>
          </div>

          <div className="work-form-wrapper">
            <form action="" method="post" className="report-form">
              <div className="report-form">
                <label htmlFor="old_password">
                  <span className="label">Current Password</span>
                  <div className="password-container">
                    <input
                      type={passwordShow ? "text" : "password"}
                      name="old_password"
                      id="old_password"
                      className="work-form-input"
                      onChange={(e) => setOldPassword(e.target.value)}
                    />
                    <img
                      src={
                        passwordShow ? `${passwordEye}` : `${passwordEyeSlash}`
                      }
                      alt=""
                      className="eye profile"
                      onClick={toggleShow}
                    />
                  </div>
                </label>

                <label htmlFor="new_password">
                  <span className="label">New Password</span>
                  <div className="password-container">
                    <input
                      type={passwordShow2 ? "text" : "password"}
                      name="new_password"
                      id="new_password"
                      className="work-form-input"
                      onChange={(e) => setNewPassword(e.target.value)}
                    />
                    <img
                      src={
                        passwordShow2 ? `${passwordEye}` : `${passwordEyeSlash}`
                      }
                      alt=""
                      className="eye profile"
                      onClick={toggleShow2}
                    />
                  </div>
                </label>
              </div>
              <button className="submit-upload" onClick={handleSubmit}>
                {loading ? (
                  <div className="center-load">
                    <span className="basic"></span>
                  </div>
                ) : (
                  `Change Password`
                )}
              </button>
              <div className={uploadFail ? "post-fail" : ""}>
                {uploadFail ? (
                  <div className="result-icon">
                    <img src={excl} />
                    {errMessage}
                  </div>
                ) : (
                  ""
                )}
              </div>
              <div className={success ? "post-success" : ""}>
                {success ? (
                  <div className="result-icon">
                    <img src={tick} />
                    Password Changed Successfully!
                  </div>
                ) : (
                  ""
                )}
              </div>
            </form>
          </div>
        </div>
      </div>
    </div>
  );
};

export default ChangePassword;
