import React, { useEffect, useState } from "react";
import LecturerNav from "../../Components/LecturerNav";
import axios from "../../api/axios";
import "../../CSS/Profile.css";

const Index = ({ supervisor }) => {
  useEffect(() => {
    document.title = "Lecturer's Home";
  }, []);

  const [supervisorInfo, setSupervisorInfo] = useState();
  const [loading, setLoading] = useState(true);
  const [loadFail, setLoadFail] = useState(false);
  const [errMessage, setErrMessage] = useState();

  useEffect(() => {
    setLoadFail(false);
    const fetchData = async () => {
      try {
        const res = await axios.get(`/api/supervisor/get/${supervisor.data}`);
        setSupervisorInfo(res.data);
        setLoading(false);
      } catch (error) {
        setLoadFail(true);
        setLoading(false);
        setErrMessage(error.response.data.message);
      }
    };
    fetchData();
  }, []);

  return (
    <div>
      <LecturerNav />
      <div className="page-wrapper">
        <div className="page-content">
          {loading ? (
            <div className="center-load">
              <span className="basic big"></span>
            </div>
          ) : loadFail ? (
            <h2 className="profile-header">{errMessage}</h2>
          ) : (
            <div className="loaded">
              <div className="heading">
                <h2 className="profile-header capitalize">
                  Hello {supervisorInfo.firstName}, Here Are Your Details
                </h2>
              </div>

              <div className="dashboard-details">
                <div className="details-half">
                  <div className="details-content">
                    <p className="details-title">Name</p>
                    <p className="details-name capitalize">
                      {supervisorInfo.firstName} {supervisorInfo.lastName}
                    </p>
                  </div>

                  <div className="details-content">
                    <p className="details-title">Phone Number</p>
                    <p className="details-name">{supervisorInfo.phone}</p>
                  </div>
                  <div className="details-content">
                    <p className="details-title">Number Of Assigned Students</p>
                    <p className="details-name">{supervisorInfo.noOfAssignedStudents}</p>
                  </div>
                </div>

                <div className="details-half">
                  <div className="details-content">
                    <p className="details-title">Email</p>
                    <p className="details-name">{supervisorInfo.email}</p>
                  </div>

                  <div className="details-content">
                    <p className="details-title">Office Location/Defence Venue</p>
                    <p className="details-name capitalize">
                      {supervisorInfo.office}
                    </p>
                  </div>
                </div>
              </div>
            </div>
          )}
        </div>
      </div>
    </div>
  );
};

export default Index;
