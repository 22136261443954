import React, { useEffect, useState } from "react";
import CoordinatorNav from "../../Components/CoordinatorNav";
import "../../CSS/Profile.css";
import axios from "../../api/axios";

const Index = ({ coordinator }) => {
  const [coordinatorInfo, setCoordinatorInfo] = useState();
  const [loading, setLoading] = useState(true);
  const [loadFail, setLoadFail] = useState(false);
  const [errMessage, setErrMessage] = useState();

  useEffect(() => {
    document.title = "Coordinator's Home";
  }, []);

  useEffect(() => {
    setLoadFail(false);
    const fetchData = async () => {
      try {
        const res = await axios.get(
          `/api/coordinators/get/${coordinator.data}`
        );
        setCoordinatorInfo(res.data);
        setLoading(false);
      } catch (error) {
        setLoadFail(true);
        setLoading(false);
        setErrMessage(error.response.data.message);
      }
    };
    fetchData();
  }, []);


  return (
    <div>
      <CoordinatorNav />
      <div className="page-wrapper">
        <div className="page-content">
          {loading ? (
            <div className="center-load">
              <span className="basic big"></span>
            </div>
          ) : loadFail ? (
            <h2 className="profile-header">{errMessage}</h2>
          ) : (
            <div className="loaded">
              <div className="heading">
                <h2 className="profile-header capitalize">
                  Hello {coordinatorInfo.firstName}, Here Are Your Details
                </h2>
              </div>

              <div className="dashboard-details">
                <div className="details-half">
                  <div className="details-content">
                    <p className="details-title">Name</p>
                    <p className="details-name capitalize">{`${coordinatorInfo.firstName} ${coordinatorInfo.lastName}`}</p>
                  </div>

                  <div className="details-content">
                    <p className="details-title">Phone Number 1</p>
                    <p className="details-name">{coordinatorInfo.phone1}</p>
                  </div>

                  <div className="details-content">
                    <p className="details-title">Phone Number 2</p>
                    <p className="details-name">
                      {coordinatorInfo.phone2 ? coordinatorInfo.phone2 : "N/A"}
                    </p>
                  </div>
                </div>

                <div className="details-half">
                  <div className="details-content">
                    <p className="details-title">Email</p>
                    <p className="details-name">{coordinatorInfo.email}</p>
                  </div>

                  <div className="details-content">
                    <p className="details-title">Office Location</p>
                    <p className="details-name capitalize">{coordinatorInfo.office}</p>
                  </div>
                </div>
              </div>
            </div>
          )}
        </div>
      </div>
    </div>
  );
};

export default Index;
