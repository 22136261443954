import React from "react";

const HomeFooter = () => {
  return (
      <div className="footer-container">
        <hr className="footer--line" />
        <footer className="content-container">
          Copyright © 2022 Babcock University. All Rights Reserved
        </footer>
      </div>
  );
};

export default HomeFooter;
