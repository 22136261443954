import React, { useState } from "react";
import { Link, NavLink, useLocation } from "react-router-dom";
import "../CSS/HomeNav.css";
import babcockLogo from "../Images/logo_coloured.png";

const HomeNav = () => {
  const [navBarOpen, setNavBarOpen] = useState(false);

  const navToggle = () => {
    setNavBarOpen(!navBarOpen);
  };

  const location = useLocation();
  const { pathname } = location;
  const splitLocation = pathname.split("/");

  return (
    <div className="nav-container">
      <nav className="nav-menu">
        <div className="menu-bar">
          <Link to="/">
            <img src={babcockLogo} alt="babcock-logo" className="nav-img" />
          </Link>
          <button className="hamburger" id="hamburger" onClick={navToggle}>
            <div className={`menu-btn ${navBarOpen ? "open" : ""}`}>
              <div className="menu-burger"></div>
            </div>
          </button>
        </div>
        <ul
          className={`nav-list-container ${navBarOpen ? "show" : ""}`}
          id="nav-list"
        >
          <li className="nav-item">
            <img
              src={babcockLogo}
              alt="babcock-logo"
              className={navBarOpen ? "none" : "nav-image"}
            />
          </li>
          <li className="nav-item">
            <NavLink to="/" className="nav-link">
              Home{" "}
              <span
                className={splitLocation[1] === "" ? "nav-link__active" : ""}
              ></span>
            </NavLink>
          </li>
          <li className="nav-item">
            <NavLink to="/studentlogin" className="nav-link">
              Students{" "}
              <span
                className={
                  splitLocation[1] === "studentlogin" ? "nav-link__active" : ""
                }
              ></span>
            </NavLink>
          </li>
          <li className="nav-item">
            <NavLink to="/lecturerlogin" className="nav-link">
              Lecturers{" "}
              <span
                className={
                  splitLocation[1] === "lecturerlogin" ? "nav-link__active" : ""
                }
              ></span>
            </NavLink>
          </li>
          <li className="nav-item">
            <NavLink to="/coordinatorlogin" className="nav-link">
              Coordinator{" "}
              <span
                className={
                  splitLocation[1] === "coordinatorlogin"
                    ? "nav-link__active"
                    : ""
                }
              ></span>
            </NavLink>
          </li>
        </ul>
      </nav>
    </div>
  );
};

export default HomeNav;
