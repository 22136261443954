import React, { useEffect, useState } from "react";
import axios from "../../api/axios";
import CoordinatorNav from "../../Components/CoordinatorNav";
import StudentTotalReport from "./StudentTotalReport";
import Pagination from "../../Pagination";
import csvIcon from "../../Icons/filetype-csv.svg"; // Import CSV icon
import "../../CSS/Profile.css";

const TotalWeeklyReport = () => {
  const [loading, setLoading] = useState(true);
  const [loadFail, setLoadFail] = useState(false);
  const [errMessage, setErrMessage] = useState("");
  const [studentData, setStudentData] = useState([]);
  const [filteredData, setFilteredData] = useState([]);
  const [currentPage, setCurrentPage] = useState(1);
  const [studentsPerPage] = useState(10);
  const [totalStudents, setTotalStudents] = useState(0);
  const [searchTerm, setSearchTerm] = useState("");

  const currentDomain = process.env.REACT_APP_BASE_URL; // Get current domain
  const getStudentScores = async (page = 1) => {
    try {
      setLoading(true);
      const res = await axios.get(`/api/coordinators/weeklyReportScore?page=${page}&limit=${studentsPerPage}`);
      const allData = res.data.data;
      setStudentData(allData);
      setFilteredData(allData);
      setTotalStudents(res.data.totalStudents);
      setLoading(false);
    } catch (error) {
      setLoadFail(true);
      setLoading(false);
      setErrMessage(error.response?.data?.message || "Failed to load data");
    }
  };

  const handleSearch = () => {
    const filtered = studentData.filter((student) =>
      (`${student.firstName} ${student.lastName}`.toLowerCase().includes(searchTerm.toLowerCase()) ||
        student.matricNumber.toLowerCase().includes(searchTerm.toLowerCase()))
    );
    setFilteredData(filtered);
  };

  const handleReset = () => {
    setSearchTerm("");
    setFilteredData(studentData);
  };

  useEffect(() => {
    document.title = "Total Weekly Report";
    getStudentScores(currentPage);
  }, [currentPage]);

  return (
    <div>
      <CoordinatorNav />
      <div className="page-wrapper">
        <div className="page-content">
          <div className="heading">
            <h2 className="profile-header">Students Total Weekly Report</h2>
          </div>
          {loading ? (
            <div className="center-load">
              <span className="basic big"></span>
            </div>
          ) : loadFail ? (
            <h2 className="profile-header">{errMessage}</h2>
          ) : (
            <div className="limiter loaded">
            
              <a
                className="search-btn export-btn"
                href={currentDomain + "/api/coordinators/weeklyReportScore/csv"} 
                download>
                <img src={csvIcon} alt="csv" />
                Export
              </a>

              <div className="search-box">
                <input
                  type="text"
                  name="search"
                  id="search"
                  className="login-form-input search-input"
                  onChange={(e) => setSearchTerm(e.target.value)}
                  value={searchTerm}
                />
                <button className="search-btn" onClick={handleSearch}>
                  Search
                </button>
                <button className="search-btn" onClick={handleReset}>
                  Reset
                </button>
              </div>

              <div className="container-table100">
                <div className="wrap-table100">
                  <div className="table100">
                    <table className="co-students">
                      <thead>
                        <tr className="table100-head">
                          <th className="column4">Student Name</th>
                          <th className="column4">Matric Number</th>
                          <th className="column4">Total Number of Weeks</th>
                          <th className="column4">Student Score</th>
                        </tr>
                      </thead>
                      <tbody>
                        <StudentTotalReport studentData={filteredData} />
                      </tbody>
                    </table>
                    <Pagination
                      totalPosts={totalStudents}
                      postsPerPage={studentsPerPage}
                      setCurrentPage={setCurrentPage}
                      currentPage={currentPage}
                      getPageData={getStudentScores}
                    />
                  </div>
                </div>
              </div>
            </div>
          )}
        </div>
      </div>
    </div>
  );
};

export default TotalWeeklyReport;
