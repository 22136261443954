import React, { useEffect, useState } from "react";
import axios from "../../api/axios";
import "../../CSS/Update.css";
import excl from "../../Icons/exclamation-lg.svg";
import tick from "../../Icons/check-lg.svg";
import BankData from '../banks.json';
import ProfileNav from "../../Components/ProfileNav";

const UpdateDetails = () => {
  useEffect(() => {
    document.title = "Update Details";
  }, []);
  const [phone, setPhone] = useState();
  const [bankList, setBankList] = useState([]);
  const [accountNumber, setAccountNumber] = useState();
  const [bankName, setBankName] = useState();
  const [sortCode, setSortCode] = useState();
  const [success, setSuccess] = useState(false);
  const [regFail, setRegFail] = useState(false);
  const [loading, setLoading] = useState(false);
  const [errMessage, setErrMessage] = useState();
  useEffect(() => {
    setBankList(BankData);
  }, []);

  const handleSubmit = async (e) => {
    e.preventDefault();
    setLoading(true);
    setRegFail(false);
    try {
  const res = await axios.patch(`/api/student/details`,
  {
    phone,
    bankName,
    sortCode,
    accountNumber
  }
  ,{
    headers: { "Content-type": "application/json" }
  });

  const responseData = res.data;
  setSuccess(true);
  setLoading(false);
  setRegFail(false);
} catch (err) {
  setRegFail(true);
  setLoading(false);
  err.response.data.errors
    ? setErrMessage(err.response.data.errors)
    : setErrMessage(err.response.data.message);
}
  };

  return (
    <div>
      <ProfileNav/>
      <div className="content-container1 page2 one-reg2">
        <div className="form-container reg">
          <form>
            <h1 className="main-header">Update Details</h1>
            <h3 className="sub-header">Please Enter Your Details Carefully</h3>

            <div className="reg-input-section">
              <div className="reg-block">
                <label htmlFor="phoneNumber" className="obj-description">
                  Phone Number
                </label>
                <input
                  type="text"
                  name="phoneNumber"
                  id="phone-number"
                  className="reg-form-input"
                  onChange={(e) => setPhone(e.target.value)}
                  required
                />
              </div>
              <div className="reg-block">
                <label htmlFor="course" className="obj-description">
                  Bank Name
                </label>
                <select
                  name="bankName"
                  id="bank-name"
                  className="reg-form-input"
                  onChange={(e) => setBankName(
                    // {
                    // ...bankName,
                    // bankName: 
                    e.target.value
                  // }
                  )}
                >
                  <option value="">Select Bank</option>
                  {bankList.map((bank, index) => (
                  <option key={index} value={bank}>
                  {bank}
                  </option>
                  ))}
                </select>
              </div>
            </div>
            <div className="reg-input-section">
              <div className="reg-block">
                <label htmlFor="bankAccountNumber" className="obj-description">
                  Bank Account Number
                </label>
                <input
                  type="text"
                  name="bankAccountNumber"
                  id="bank-acc-num"
                  className="reg-form-input"
                  onChange={(e) =>
                    setAccountNumber(
                      // ...accountNumber,
                      // accountNumber:
                       e.target.value
                    )
                  }
                  required
                />
              </div>

              <div className="reg-block">
                <label htmlFor="bankSortCode" className="obj-description">
                  Bank Sort Code
                </label>
                <input
                  type="text"
                  name="bankSortCode"
                  id="sort-code"
                  className="reg-form-input"
                  onChange={(e) =>
                    setSortCode(
                      // { ...sortCode, sortCode:
                       e.target.value
                      //  }
                       )
                  }
                />
              </div>
            </div>
            
            <div className="reg-input-section">
              <div className="reg-block cntr">
                <button
                  className="login-btn"
                  type="submit"
                  onClick={handleSubmit}
                >
                  {loading ? (
                    <div className="center-load">
                      <span className="basic"></span>
                    </div>
                  ) : (
                    `Update`
                  )}
                </button>
              </div>
            </div>
            <div className={regFail ? "post-fail" : ""}>
              {regFail ? (
                <div className="result-icon">
                  <img src={excl} />
                  {errMessage}
                </div>
              ) : (
                ""
              )}
            </div>
            <div className={success ? "post-success" : ""}>
              {success ? (
                <div className="result-icon">
                  <img src={tick} />
                  Profile Updated Successfuly!
                </div>
              ) : (
                ""
              )}
            </div>
          </form>
        </div>
      </div>
    </div>
  );
};

export default UpdateDetails;
