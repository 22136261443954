export const facultyOptions = [
  {
    value: "Computing and Engineering Sciences"
  },
  {
    value: "Science and Technology"
  },
  {
    value: "Nursing Sciences"
  },
  {
    value: "Basic Medical Sciences"
  },
  {
    value: "Management Sciences"
  },
  {
    value: "Veronica Adeleke School of Social Sciences"
  }
];

export const departmentOptions = [
  {
    value: "Computer Science",
    schoolName: "Computing and Engineering Sciences"
  },
  {
    value: "Information Technology",
    schoolName: "Computing and Engineering Sciences"
  },
  {
    value: "Software Engineering",
    schoolName: "Computing and Engineering Sciences"
  },
  {
    value: "Microbiology",
    schoolName: "Science and Technology"
  },
  {
    value: "Agric and Industrial Technology",
    schoolName: "Science and Technology"
  },
  {
    value: "Basic Sciences",
    schoolName: "Science and Technology"
  },
  {
    value: "Nursing",
    schoolName: "Nursing Sciences"
  },
  {
    value: "Biochemistry",
    schoolName: "Basic Medical Sciences"
  },
  {
    value: "Nutrition",
    schoolName: "Basic Medical Sciences"
  },
  {
    value: "Information Resource Management",
    schoolName: "Management Sciences"
  },
  {
    value: "Mass Communication",
    schoolName: "Veronica Adeleke School of Social Sciences"
  }
];

export const courseOptions = [
  {
    value: "Computer Science",
    deptName: "Computer Science"
  },
  {
    value: "Computer Technology",
    deptName: "Computer Science"
  },
  {
    value: "Computer Information Systems",
    deptName: "Computer Science"
  },
  {
    value: "Software Engineering",
    deptName: "Software Engineering"
  },
  {
    value: "Information Technology",
    deptName: "Information Technology"
  },
  {
    value: "Microbiology",
    deptName: "Microbiology"
  },
  {
    value: "Agric",
    deptName: "Agric and Industrial Technology"
  },
  {
    value: "Physics/Electronics",
    deptName: "Basic Sciences"
  },
  {
    value: "Mathematics",
    deptName: "Basic Sciences"
  },
  {
    value: "Chemistry",
    deptName: "Basic Sciences"
  },
  {
    value: "Biochemistry",
    deptName: "Basic Sciences"
  },
  {
    value: "Nursing",
    deptName: "Nursing"
  },
  {
    value: "Nutrition and Diatetics",
    deptName: "Nutrition"
  },
  {
    value: "Information Resource Management",
    deptName: "Information Resource Management"
  },
  {
    value: "Mass Communication",
    deptName: "Mass Communication"
  }
];
