import React, { useEffect, useState } from "react";
import { useNavigate } from "react-router-dom";
import CoordinatorNav from "../../Components/CoordinatorNav";
import excl from "../../Icons/exclamation-lg.svg";
import tick from "../../Icons/check-lg.svg";
import axios from "../../api/axios";
import "../../CSS/Profile.css";

const EditDetails = ({ coordinator }) => {
  const [firstName, setFirstName] = useState();
  const [lastName, setLastName] = useState();
  const [phone1, setPhone1] = useState();
  const [phone2, setPhone2] = useState();
  const [office, setOffice] = useState();
  const [errMessage, setErrMessage] = useState();
  const [uploadFail, setUploadFail] = useState();
  const [success, setSucess] = useState(false);
  const [loading, setLoading] = useState(false);
  const navigate = useNavigate()

  const handleSubmit = async (e) => {
    e.preventDefault();
    setUploadFail(false);
    setLoading(true);
    try {
      const res = await axios.patch(
        `/api/coordinators/update/${coordinator.data}`,
        JSON.stringify({ firstName, lastName, phone1, phone2, office }),
        {
          headers: { "Content-type": "application/json" }
        }
      );
      setSucess(true);
      setLoading(false);
      setUploadFail(false);
      setTimeout(() => {
        navigate("/coordinator/index");
      }, 2000);
    } catch (err) {
      setLoading(false);
      setUploadFail(true);
      setErrMessage(err.response.data.message);
    }
  };

  useEffect(() => {
    document.title = "Edit Details";
  }, []);
  return (
    <div>
      <CoordinatorNav />
      <div className="page-wrapper">
        <div className="page-content">
          <div className="heading">
            <h2 className="profile-header">Edit Coordinator's Details</h2>
          </div>

          <div className="work-form-wrapper">
            <form className="grid-small">
              <div className="work-form">
                <div className="work-form-half half-1">
                  <label htmlFor="first-name">
                    <span className="label">First Name</span>
                    <input
                      type="text"
                      name="first-name"
                      id="first-name"
                      className="work-form-input"
                      //required
                      onChange={(e) => setFirstName(e.target.value)}
                    />
                  </label>

                  <label htmlFor="last-name">
                    <span className="label">Last Name</span>
                    <input
                      type="text"
                      name="last-name"
                      id="last-name"
                      className="work-form-input"
                      onChange={(e) => setLastName(e.target.value)}
                      //required
                    />
                  </label>

                  <label htmlFor="email">
                    <span className="label">Email</span>
                    <input
                      type="email"
                      name="email"
                      id="email"
                      className="work-form-input input-disabled"
                      disabled
                      //required
                    />
                  </label>
                </div>
                <div className="work-form-half half-2">
                  <label htmlFor="number1">
                    <span className="label">Phone Number 1</span>
                    <input
                      type="text"
                      name="number1"
                      id="number1"
                      className="work-form-input"
                      onChange={(e) => setPhone1(e.target.value)}
                      //required
                    />
                  </label>

                  <label htmlFor="number1">
                    <span className="label">Phone Number 2</span>
                    <input
                      type="text"
                      name="number1"
                      id="number1"
                      className="work-form-input"
                      onChange={(e) => setPhone2(e.target.value)}
                      //required
                    />
                  </label>

                  <label htmlFor="office-location">
                    <span className="label">Office Location</span>
                    <input
                      type="text"
                      name="office-location"
                      id="office-location"
                      onChange={(e) => setOffice(e.target.value)}
                      className="work-form-input"
                    />
                  </label>
                </div>
              </div>
              <button className="submit-upload" onClick={handleSubmit}>
                {loading ? (
                  <div className="center-load">
                    <span className="basic"></span>
                  </div>
                ) : (
                  `Update Details`
                )}
              </button>
              <div className={uploadFail ? "post-fail" : ""}>
                {uploadFail ? (
                  <div className="result-icon">
                    <img src={excl} />
                    {errMessage}
                  </div>
                ) : (
                  ""
                )}
              </div>
              <div className={success ? "post-success" : ""}>
                {success ? (
                  <div className="result-icon">
                    <img src={tick} />
                    Details Updated Successfully!
                  </div>
                ) : (
                  ""
                )}
              </div>
            </form>
          </div>
        </div>
      </div>
    </div>
  );
};

export default EditDetails;
