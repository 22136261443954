import React, { useEffect, useState } from "react";
import axios from "../../api/axios";
import CoordinatorNav from "../../Components/CoordinatorNav";
import "../../CSS/Profile.css";

const AssignDefence = () => {
  useEffect(() => {
    document.title = "Assign Defence Supervisors";
  }, []);

  const [loading, setLoading] = useState(true);
  const [submitLoading, setSubmitLoading] = useState(false);
  const [loadFail, setLoadFail] = useState(false);
  const [errMessage, setErrMessage] = useState();
  const [studentData, setStudentData] = useState();
  const [supervisorData, setSupervisorData] = useState();
  const [studentCode, setStudentCode] = useState();
  const [supervisorId, setsupervisorId] = useState();
  const [success, setSucess] = useState(false);
  const [regFail, setRegFail] = useState(false);

  useEffect(() => {
    setLoadFail(false);
    const fetchData = async () => {
      try {
        const res = await axios.get(`/api/coordinators/students`);
        setStudentData(res.data.students);
      } catch (error) {
        setLoadFail(true);
        setLoading(false);
        setErrMessage(error.response.data.message);
      }
      try {
        const res = await axios.get(`/api/coordinators/supervisors`);
        setSupervisorData(res.data);
        setLoading(false);
      } catch (error) {
        setLoadFail(true);
        setLoading(false);
        setErrMessage(error.response.data.message);
      }
    };
    fetchData();
  }, []);

  const handleSubmit = async (e) => {
    e.preventDefault();
    setSubmitLoading(true);
    setRegFail(false);
    try {
      const res = await axios.post(
        `/api/coordinators/assignDefenseSupervisor`,
        JSON.stringify({ studentCode, supervisorId }),
        {
          headers: { "Content-type": "application/json" }
        }
      );
      setSucess(true);
      setSubmitLoading(false);
      setRegFail(false);
      setTimeout(() => {
        setSucess(false);
      }, 3000);
    } catch (err) {
      setRegFail(true);
      setSubmitLoading(false);
      err.response.data.errors
        ? setErrMessage(err.response.data.errors)
        : setErrMessage(err.response.data.message);
    }
  };

  return (
    <div>
      <CoordinatorNav />
      <div className="page-wrapper">
        <div className="page-content">
          <div className="heading">
            <h2 className="profile-header">Assign Defence Supervisors</h2>
          </div>
          {loading ? (
            <div className="center-load">
              <span className="basic big"></span>
            </div>
          ) : loadFail ? (
            <h2 className="profile-header">{errMessage}</h2>
          ) : (
            <div className="loaded">
              <div className="limiter">
                <div className="container-table100">
                  <div className="wrap-table100">
                    <div className="table100">
                      <table className="co defence-table">
                        <thead>
                          <tr className="table100-head">
                            <th className="column6">Student Name</th>
                            <th className="column3">Matric Number</th>
                            <th className="column4">Course</th>
                            <th className="column2">Student's Phone number</th>
                            <th className="column4">Company Name</th>
                            <th className="column4">Company Address</th>
                            <th className="column5">Select Def. Supervisor</th>
                            <th className="column5">Assign</th>
                          </tr>
                        </thead>
                        <tbody>
                          {studentData.map((student) => {
                            return (
                              <tr key={student._id}>
                                <td className="column6 capitalize">
                                  {`${student.lastName} ${student.firstName} ${student.middleName}`}
                                </td>
                                <td className="column3">{student.matricNo}</td>
                                <td className="column4 capitalize">
                                  {student.course}
                                </td>
                                <td className="column2">{student.phone}</td>
                                <td className="column4">{student.company ? student.company.name : ''}</td>
                                <td className="column4">{student.company ? student.company.address : ''}</td>

                                <td className="column5">
                                  <select
                                    name=""
                                    id=""
                                    className="select-supervisor capitalize"
                                    onChange={(e) => {
                                      setsupervisorId(e.target.value);
                                      setStudentCode(student.studentCode);
                                    }}
                                  >
                                    <option value="">
                                      Select Def. Supervisor
                                    </option>
                                    {supervisorData.map((supervisor) => {
                                      return (
                                        <option
                                          key={supervisor._id}
                                          value={supervisor._id}
                                        >
                                          {supervisor.firstName}{" "}
                                          {supervisor.lastName}
                                        </option>
                                      );
                                    })}
                                  </select>
                                </td>
                                <td className="column5">
                                  <button
                                    className="submit-upload"
                                    onClick={handleSubmit}
                                  >
                                    {student.studentCode === studentCode &&
                                    submitLoading ? (
                                      <div className="center-load">
                                        <span className="basic"></span>
                                      </div>
                                    ) : (
                                      `Assign`
                                    )}
                                  </button>
                                  <div
                                    className={
                                      student.studentCode === studentCode &&
                                      regFail
                                        ? "post-fail"
                                        : ""
                                    }
                                  >
                                    {student.studentCode === studentCode &&
                                    regFail ? (
                                      <div className="result-icon">
                                        {errMessage}
                                      </div>
                                    ) : (
                                      ""
                                    )}
                                  </div>
                                  <div
                                    className={
                                      student.studentCode === studentCode &&
                                      success
                                        ? "post-success"
                                        : ""
                                    }
                                  >
                                    {student.studentCode === studentCode &&
                                    success ? (
                                      <div className="result-icon">
                                        Assigned Successfully!
                                      </div>
                                    ) : (
                                      ""
                                    )}
                                  </div>
                                </td>
                              </tr>
                            );
                          })}
                        </tbody>
                      </table>
                    </div>
                  </div>
                </div>
              </div>
            </div>
          )}
        </div>
      </div>
    </div>
  );
};

export default AssignDefence;
