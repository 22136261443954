import React, { useEffect, useState } from "react";
import { Link, useNavigate } from "react-router-dom";
import HomeNav from "../Components/HomeNav";
import HomeFooter from "../Components/HomeFooter";
import { useContext, useRef } from "react";
import axios from "../api/axios";
import { Context } from "../Context/Context";
import "../CSS/Login.css";


const StudentLogin = () => {
  useEffect(() => {
    document.title = "Student Verification";
  }, []);

  const [email, setEmail] = useState();
  const purpose = "registration";
  const [verificationFail, setVerificationFail] = useState(false);
  const [verificationSuccess, setVerificationSuccess] = useState(false);
  const [success, setSuccess] = useState(false);
  const { dispatch, isFetching } = useContext(Context);
  const [loading, setLoading] = useState(false);
  const [errMessage, setErrMessage] = useState();

  const navigate = useNavigate()

  const handleSubmit = async (e) => {
    e.preventDefault();
    setLoading(true);
    setVerificationFail(false);
    setVerificationSuccess(false);
    dispatch({ type: "LOGIN_START" });

    const userEmail = email;

    sessionStorage.setItem('userEmail', userEmail)
    // console.log(userEmail)
    // setEmail(userEmail)
    try {
      const res = await axios.post(
        `/api/auth/sendOTP`,
        JSON.stringify({
          email,
          purpose
        }),
        {
          headers: { "Content-type": "application/json" }
        }
      );
      dispatch({ type: "LOGIN_SUCCESS", payload: res.data });
      setLoading(false);
      if (res.data) {
        setVerificationFail(false)
        setVerificationSuccess(true)
        setSuccess(res.data.message)
        navigate("/changePassword");
    }
    } catch (err) {
      setVerificationFail(true);
      setVerificationSuccess(false);
      setLoading(false);
      err.response.data.errors
        ? setErrMessage(err.response.data.errors)
        : setErrMessage(err.response.data.message);
    }
  };
  return (
    <div>
      <HomeNav />
      <div className="content-container1 page">
        <div className="form-container">
          <form>

              <div className={verificationFail ? "post-fail" : ""}>
                {verificationFail ? (
                  <div className="result-icon">
                    {errMessage}
                  </div>
                ) : ("")}
  
              </div>
            <div className="input-section">
              <h1 className="main-header">Verify Email</h1>
              {/* <h3 className="sub-header login-text">Verify OTP</h3> */}

              <input
                type="email"
                name="email"
                id="email"
                placeholder="Email"
                onChange={(e) => setEmail(e.target.value)}
                required
                className="login-form-input"
              />
              <button className="login-btn" onClick={handleSubmit}>
                {loading ? (
                  <div className="center-load">
                    <span className="basic"></span>
                  </div>
                ) : (
                  `Verify`
                )}
              </button>
              <div className={verificationSuccess ? "post-success" : ""}>
                {verificationSuccess ? (
                  <div className="result-icon">
                    {success}
                  </div>
                ) : (
                  ""
                )}
              </div>
            </div>
          </form>
        </div>
        {/* <HomeFooter /> */}
      </div>
    </div>
  );
};

export default StudentLogin;
