import React from "react";

const StudentTotalReport = ({ studentData }) => {
  return (
    <>
      {studentData.map((student, index) => (
        <tr key={index}>
          <td className="column4 capitalize">{`${student.firstName} ${student.lastName}`}</td>
          <td className="column4">{student.matricNumber}</td>
          <td className="column4">{student.reportCount}</td>
          <td className="column4">{student.marks}</td>
        </tr>
      ))}
    </>
  );
};

export default StudentTotalReport;
