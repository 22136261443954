import React, { useState } from "react";
import { Link, NavLink, useLocation, useNavigate } from "react-router-dom";
import buLogo from "../Images/bulogo.png";
import logoStack from "../Images/logo_stack.png";
import menuBtn from "../Icons/bx-menu-alt-left.svg";
import arrowBtn from "../Icons/bx-left-arrow-alt.svg";
import dashboardIcon from "../Icons/bxs-dashboard.svg";
import uploadIcon from "../Icons/document-text.svg";
import reportIcon from "../Icons/file-lines-solid.svg";
import contactIcon from "../Icons/bxs-contact.svg";
import logoutIcon from "../Icons/bxs-log-out.svg";
import changePassword from "../Icons/change-password.svg";
import "../CSS/ProfileNav.css";
import { Context } from "../Context/Context";
import axios from "../api/axios";
import { useContext } from "react";

const ProfileNav = () => {
  const [navBarOpen, setNavBarOpen] = useState(false);
  const { student, dispatch } = useContext(Context);
  const navigate = useNavigate();

  const navToggle = () => {
    setNavBarOpen(!navBarOpen);
  };

  const location = useLocation();
  const { pathname } = location;
  const splitLocation = pathname.split("/");

  async function logOut(e) {
    try {
      const res = await axios.post(`/api/auth/logout`);
      dispatch({ type: "LOGOUT" });
      navigate("/studentlogin");
    } catch (err) {}
  }

  return (
    <div>
      <nav className={`sidebar ${navBarOpen ? "active" : ""}`}>
        <div className="logo-content">
          <div className="logo">
            <img src={buLogo} alt="" className="logo-img" />
            <img src={logoStack} alt="babcock" className="logo-img-name" />
          </div>
          <button className="sidebar-burger" id="sidebar-btn" onClick={navToggle}>
            <div>
              <img
                src={menuBtn}
                alt=""
                className={`sidebar-btn btn-h ${navBarOpen ? "active" : ""}`}
              />
            </div>
            <div>
              <img
                src={arrowBtn}
                alt=""
                className={`sidebar-btn btn-x ${navBarOpen ? "active" : ""}`}
              />
            </div>
          </button>
        </div>

        <ul className="sidebar-list">
          <li>
            <NavLink
              to="/students/index"
              //   className="sidebar-link"
              className={
                splitLocation[2] === "index"
                  ? "sidebar-link active-link"
                  : "sidebar-link"
              }
            >
              <img src={dashboardIcon} alt="" />
              <span className="link-name">Dashboard</span>
            </NavLink>
          </li>

          <li>
            <NavLink
              to="/students/upload"
              className={
                splitLocation[2] === "upload"
                  ? "sidebar-link active-link"
                  : "sidebar-link"
              }
            >
              <img src={uploadIcon} alt="" />
              <span className="link-name">Add Work Details</span>
            </NavLink>
          </li>

          <li>
            <NavLink
              to="/students/report"
              className={
                splitLocation[2] === "report"
                  ? "sidebar-link active-link"
                  : "sidebar-link"
              }
            >
              <img src={reportIcon} alt="" />
              <span className="link-name">Weekly Report Upload</span>
            </NavLink>
          </li>

          <li>
            <NavLink
              to="/students/changepassword"
              className={
                splitLocation[2] === "changepassword"
                  ? "sidebar-link active-link"
                  : "sidebar-link"
              }
              title="Change Password"
            >
              <img src={changePassword} alt="" />
              <span className="link-name">Change Password</span>
            </NavLink>
          </li>

          <li>
            <NavLink
              to="/students/updateDetails"
              className={
                splitLocation[2] === "updateDetails"
                  ? "sidebar-link active-link"
                  : "sidebar-link"
              }
              title="Update Details"
            >
              <img src={uploadIcon} alt="" />
              <span className="link-name">Update Details</span>
            </NavLink>
          </li>

          <li>
            <NavLink
              to="/students/contact"
              className={
                splitLocation[2] === "contact"
                  ? "sidebar-link active-link"
                  : "sidebar-link"
              }
            >
              <img src={contactIcon} alt="" />
              <span className="link-name">Contact/ITF Offices</span>
            </NavLink>
          </li>
        </ul>

        <div className="logout">
          <a className="sidebar-link" onClick={logOut}>
            <img src={logoutIcon} alt="" />
            <span className="link-name">Logout</span>
          </a>
        </div>
      </nav>
    </div>
  );
};

export default ProfileNav;
