import React, { useEffect, useState } from "react";
import LecturerNav from "../../Components/LecturerNav";
import "../../CSS/Profile.css";
import "../../CSS/Table.css";
import axios from "../../api/axios";

const Inspection = () => {
  useEffect(() => {
    document.title = "Inspection List";
  }, []);

  const [loading, setLoading] = useState(true);
  const [submitLoading, setSubmitLoading] = useState(false);
  const [loadFail, setLoadFail] = useState(false);
  const [errMessage, setErrMessage] = useState();
  const [studentData, setStudentData] = useState();
  const [studentCode, setStudentCode] = useState();
  const [date, setDate] = useState();
  const [success, setSucess] = useState(false);
  const [regFail, setRegFail] = useState(false);

  function getCurrentDateTimeString(date) {
    const year = date.getFullYear();
    const month = String(date.getMonth() + 1).padStart(2, '0'); 
    const day = String(date.getDate()).padStart(2, '0');
    const hours = String(date.getHours()).padStart(2, '0');
    const minutes = String(date.getMinutes()).padStart(2, '0');
  
    return `${year}-${month}-${day}T${hours}:${minutes}`;
  }

 
  useEffect(() => {
    setLoadFail(false);
    const fetchData = async () => {
      try {
        const res = await axios.get(
          `/api/supervisor/assignedStudentsForInspection`
        );
        const formattedStudentData = res.data.map(student => ({
          ...student,
          formattedAssignedDate: getCurrentDateTimeString(new Date(student.assignedDate))
        }));
        setStudentData(formattedStudentData);
        setLoading(false);
      } catch (error) {
        setLoadFail(true);
        setLoading(false);
        setErrMessage(error.response.data.message);
      }
    };
    fetchData();
  }, []);

  const handleSubmit = async (e) => {
    e.preventDefault();
    setSubmitLoading(true);
    setRegFail(false);
    try {
      const res = await axios.patch(
        `/api/supervisor/updateInspectionTime`,
        JSON.stringify({ studentCode, date }),
        {
          headers: { "Content-type": "application/json" }
        }
      );
      setSucess(true);
      setSubmitLoading(false);
      setRegFail(false);
      setTimeout(() => {
        setSucess(false);
      }, 3000);
    } catch (err) {
      setRegFail(true);
      setSubmitLoading(false);
      err.response.data.errors
        ? setErrMessage(err.response.data.errors)
        : setErrMessage(err.response.data.message);
    }
  };

  return (
    <div>
      <LecturerNav />
      <div className="page-wrapper">
        <div className="page-content">
          <div className="heading">
            <h2 className="profile-header">Student Inspection List</h2>
          </div>
          {loading ? (
            <div className="center-load">
              <span className="basic big"></span>
            </div>
          ) : loadFail ? (
            <h2 className="profile-header">{errMessage}</h2>
          ) : (
            <div className="loaded">
              <div className="limiter">
                <div className="container-table100">
                  <div className="wrap-table100">
                    <div className="table100">
                      <table className="inspection-table">
                        <thead>
                          <tr className="table100-head">
                            <th className="column5">Student Name</th>
                            <th className="column3">Phone Number</th>
                            <th className="column5">Email</th>
                            <th className="column3">Company Name</th>
                            <th className="column2">Address</th>
                            <th className="column3">State</th>
                            <th className="column2">Set Ins. Date</th>
                            <th className="column3">Assign</th>
                          </tr>
                        </thead>
                        <tbody>
                          {studentData.map((student) => {
                            console.log(student.formattedAssignedDate)
                            return (
                              <tr key={student._id}>
                                <td className="column5 capitalize">
                                  {student.studentDetails.name}
                                </td>
                                <td className="column3">
                                  {student.studentDetails.phone}
                                </td>
                                <td className="column3">
                                  {student.studentDetails.email}
                                </td>
                                {student.companyDetails ? (
                                  <>
                                    <td className="column3 capitalize">
                                      {student.companyDetails.name}
                                    </td>
                                    <td className="column2 capitalize">
                                      {student.companyDetails.address}
                                    </td>
                                    <td className="column2 capitalize">
                                      {student.companyDetails.state}
                                    </td>
                                  </>
                                ) : (
                                  <>
                                    <td>N/A</td>
                                    <td>N/A</td>
                                    <td>N/A</td>
                                  </>
                                )}
                                <td className="column3">
                                  <input
                                    type="datetime-local"
                                    name="deadline"
                                    id="deadline"
                                    className="report-date2"
                                    defaultValue={student.formattedAssignedDate}
                                    onChange={(e) => {
                                      setStudentCode(
                                        student.studentDetails.studentCode
                                      );
                                      setDate(e.target.value);
                                    }}
                                  />
                                </td>
                                <td className="column3">
                                  <button
                                    className="submit-upload"
                                    onClick={handleSubmit}
                                  >
                                    {student.studentDetails.studentCode ===
                                      studentCode && submitLoading ? (
                                      <div className="center-load">
                                        <span className="basic"></span>
                                      </div>
                                    ) : (
                                      `Assign`
                                    )}
                                  </button>
                                  <div
                                    className={
                                      student.studentDetails.studentCode ===
                                        studentCode && regFail
                                        ? "post-fail"
                                        : ""
                                    }
                                  >
                                    {student.studentDetails.studentCode ===
                                      studentCode && regFail ? (
                                      <div className="result-icon">
                                        {errMessage}
                                      </div>
                                    ) : (
                                      ""
                                    )}
                                  </div>
                                  <div
                                    className={
                                      student.studentDetails.studentCode ===
                                        studentCode && success
                                        ? "post-success"
                                        : ""
                                    }
                                  >
                                    {student.studentDetails.studentCode ===
                                      studentCode && success ? (
                                      <div className="result-icon">
                                        Assigned Successfully!
                                      </div>
                                    ) : (
                                      ""
                                    )}
                                  </div>
                                </td>
                              </tr>
                            );
                          })}
                        </tbody>
                      </table>
                    </div>
                  </div>
                </div>
              </div>
            </div>
          )}
        </div>
      </div>
    </div>
  );
};

export default Inspection;
