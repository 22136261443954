import React, { useEffect, useState } from "react";
import axios from "../../api/axios";
import CoordinatorNav from "../../Components/CoordinatorNav";
import "../../CSS/Profile.css";
import Pagination from "../../Pagination";
import upIcon from "../../Icons/caret-up-solid.svg";
import downIcon from "../../Icons/caret-down-solid.svg";

const AssignInspector = () => {
  useEffect(() => {
    document.title = "Assign Inspection Supervisors";
  }, []);

  const [loading, setLoading] = useState(true);
  const [submitLoading, setSubmitLoading] = useState(false);
  const [loadFail, setLoadFail] = useState(false);
  const [errMessage, setErrMessage] = useState('');
  const [studentData, setStudentData] = useState([]);
  const [supervisorData, setSupervisorData] = useState();
  const [studentCode, setStudentCode] = useState();
  const [supervisorId, setSupervisorId] = useState();
  const [success, setSuccess] = useState(false);
  const [regFail, setRegFail] = useState(false);
  const [sortConfig, setSortConfig] = useState({ key: '', direction: '' });
  const [searchTerm, setSearchTerm] = useState("");

  // pagination
  const [currentPage, setCurrentPage] = useState(1);
  const studentsPerPage = 10
  const [noOfStudents, setNoOfStudents] = useState(0);

  const getPageData = async function (page, key, direction) {
    try {
      setLoading(true);

      // check if the key & direction has been set normally on the useState
      if (typeof key === 'undefined' || typeof direction === 'undefined') {
        key = sortConfig.key;
        direction = sortConfig.direction;
      }

      const res = await axios.get(
        `/api/coordinators/students?limit=${studentsPerPage}&page=${page}&sortBy=${key}&sortOrder=${direction}`
      );

      setNoOfStudents(res.data.totalStudents)
      setStudentData(res.data.students);
      setLoading(false);
    } catch (error) {
      setLoadFail(true);
      setLoading(false);
      setErrMessage(error.response.data.message);
    }
  };

  const search = async () => {
    try {
      if (searchTerm.length < 3) {
        return;
      }
      
      setLoading(true);
      const res = await axios.get(
        `/api/coordinators/search/students?q=${searchTerm}`
      );

      setNoOfStudents(res.data.length)
      console.log(noOfStudents)
      setStudentData(res.data);
      setLoading(false);
    } catch (error) {
      setLoadFail(true);
      setLoading(false);
      setErrMessage(error.response.data.message);
    }
  };

  useEffect(() => {
    setLoadFail(false);
    const fetchData = async () => {
      try {
        const res =  await axios.get(`/api/coordinators/students?limit=${studentsPerPage}`);
        setNoOfStudents(res.data.totalStudents)
        setStudentData(res.data.students);
        setNoOfStudents(res.data.totalStudents)
      } catch (error) {
        setLoadFail(true);
        setLoading(false);
        setErrMessage(error.response.data.message);
      }
      try {
        const res = await axios.get(`/api/coordinators/supervisors`);
        setSupervisorData(res.data);
        setLoading(false);
      } catch (error) {
        setLoadFail(true);
        setLoading(false);
        setErrMessage(error.response.data.message);
      }
    };
    fetchData();
  }, []);

  //assign inspeactor
  const handleSubmit = async (e) => {
    e.preventDefault();
    setSubmitLoading(true);
    setRegFail(false);
    try {
      const res = await axios.post(
        `/api/coordinators/assignInspectionSupervisor`,
        JSON.stringify({ studentCode, supervisorId }),
        {
          headers: { "Content-type": "application/json" }
        }
      );
      setSuccess(true);
      setSubmitLoading(false);
      setRegFail(false);
      setTimeout(() => {
        setSuccess(false);
      }, 3000);
    } catch (err) {
      setRegFail(true);
      setSubmitLoading(false);
      err.response.data.errors
        ? setErrMessage(err.response.data.errors)
        : setErrMessage(err.response.data.message);
    }
  };

  const sortData = (key) => {
    let direction = 'asc';
    if (sortConfig.key === key && sortConfig.direction === 'asc') {
      direction = 'desc';
    }

    setSortConfig({ key, direction });

    getPageData(currentPage, key, direction);
  };
  return (
    <div>
      <CoordinatorNav />
      <div className="page-wrapper">
        <div className="page-content">
          <div className="heading">
            <h2 className="profile-header">Assign Inspection Supervisors</h2>
          </div>
          {loading ? (
            <div className="center-load">
              <span className="basic big"></span>
            </div>
          ) : loadFail ? (
            <h2 className="profile-header">{errMessage}</h2>
          ) : (
            <div className="loaded">
              <div className="limiter">
                <div className="container-table100">
                  <div className="wrap-table100">
                    <div className="table100">
                      <div className="search-box">
                      <input
                        type="text"
                        name="search"
                        id="search"
                        className="login-form-input search-input"
                        onChange={(e) => setSearchTerm(e.target.value)}
                        value={searchTerm}
                      />
                      <button 
                        className="search-btn" onClick={search}>
                          Search
                      </button>
                      <button 
                        className="search-btn"
                        onClick={() => window.location.reload()}>Reset</button>
                    </div>
                      <table className="co-inspection-table">
                        <thead>
                          <tr className="table100-head">
                            <th className="column6">Student Name</th>
                            <th className="column3">Matric Number</th>
                            <th className="column4" onClick={() => sortData('course')} style={{cursor:'pointer'}}>
                              Course
                              { sortConfig.key === 'course' && sortConfig.direction === 'asc' && 
                                <img src={upIcon} alt="" style={{display: 'inline', height: '20px', marginLeft: '15px'}} />
                              }
                              { sortConfig.key === 'course' && sortConfig.direction === 'desc' && 
                                <img src={downIcon} alt="" style={{display: 'inline', height: '20px', marginLeft: '15px'}} />
                              }
                            </th>
                            <th className="column2">Student's Phone number</th>
                            <th className="column4">Company Name</th>
                            <th className="column4" onClick={() => sortData('company.state')} style={{cursor:'pointer'}}>
                              State
                              { sortConfig.key === 'company.state' && sortConfig.direction === 'asc' && 
                                <img src={upIcon} alt="" style={{display: 'inline', height: '20px', marginLeft: '15px'}} />
                              }
                              { sortConfig.key === 'company.state' && sortConfig.direction === 'desc' && 
                                <img src={downIcon} alt="" style={{display: 'inline', height: '20px', marginLeft: '15px'}} />
                              }
                            </th>
                            <th className="column4" onClick={() => sortData('company.LGA')} style={{cursor:'pointer'}}>
                              LGA
                              { sortConfig.key === 'company.LGA' && sortConfig.direction === 'asc' && 
                                <img src={upIcon} alt="" style={{display: 'inline', height: '20px', marginLeft: '15px'}} />
                              }
                              { sortConfig.key === 'company.LGA' && sortConfig.direction === 'desc' && 
                                <img src={downIcon} alt="" style={{display: 'inline', height: '20px', marginLeft: '15px'}} />
                              }
                            </th>
                            <th className="column4">Address</th>
                            <th className="column5">Assigned Inspector</th>
                            <th className="column5">Select Inspector</th>
                            <th className="column5">Assign</th>
                          </tr>
                        </thead>
                        <tbody>
                            {studentData.map((student) => {
                              return (
                                <tr key={student._id}>
                                  <td className="column6 capitalize">
                                    {`${student.firstName || "N/A"} ${student.middleName || "N/A"} ${student.lastName || "N/A"}`}
                                  </td>
                                  <td className="column3">{student.matricNo || "N/A"}</td>
                                  <td className="column4 capitalize">
                                    {student.course || "N/A"}
                                  </td>
                                  <td className="column2">{student.phone || "N/A"}</td>
                                  <td className="column4">{student.company ? student.company.name || "N/A" : 'N/A'}</td>
                                  <td className="column4">{student.company ? student.company.state || "N/A" : 'N/A'}</td>
                                  <td className="column4">{student.company ? student.company.LGA || "N/A" : 'N/A'}</td>
                                  <td className="column4">{student.company ? student.company.address || "N/A" : 'N/A'}</td>
                                  
                                  <td className="column4">
                                    {student.assignedSupervisorInfo ? `${student.assignedSupervisorInfo.firstName} ${student.assignedSupervisorInfo.lastName}` : "N/A"}
                                 </td>


                                  <td className="column5">
                                    <select
                                      name=""
                                      id=""
                                      className="select-supervisor capitalize"
                                      onChange={(e) => {
                                        setSupervisorId(e.target.value);
                                        setStudentCode(student.studentCode);
                                      }}
                                    >
                                      <option value="">Select Inspector</option>
                                      {supervisorData.map((supervisor) => {
                                        return (
                                          <option
                                            key={supervisor._id}
                                            value={supervisor._id}
                                          >
                                            {supervisor.firstName || "N/A"}{" "}
                                            {supervisor.lastName || "N/A"}
                                          </option>
                                        );
                                      })}
                                    </select>
                                  </td>
                                  <td className="column5">
                                    <button
                                      className="submit-upload"
                                      onClick={handleSubmit}
                                    >
                                      {student.studentCode === studentCode &&
                                      submitLoading ? (
                                        <div className="center-load">
                                          <span className="basic"></span>
                                        </div>
                                      ) : (
                                        `Assign`
                                      )}
                                    </button>
                                    <div
                                      className={
                                        student.studentCode === studentCode &&
                                        regFail
                                          ? "post-fail"
                                          : ""
                                      }
                                    >
                                      {student.studentCode === studentCode &&
                                      regFail ? (
                                        <div className="result-icon">
                                          {errMessage}
                                        </div>
                                      ) : (
                                        ""
                                      )}
                                    </div>
                                    <div
                                      className={
                                        student.studentCode === studentCode &&
                                        success
                                          ? "post-success"
                                          : ""
                                      }
                                    >
                                      {student.studentCode === studentCode &&
                                      success ? (
                                        <div className="result-icon">
                                          Assigned Successfully!
                                        </div>
                                      ) : (
                                        ""
                                      )}
                                    </div>
                                  </td>
                                </tr>
                              );
                            })}
                          </tbody>

                      </table>
                      <div className="pagination-container">
                      <Pagination
                        totalPosts={noOfStudents}
                        postsPerPage={studentsPerPage}
                        setCurrentPage={setCurrentPage}
                        currentPage={currentPage}
                        getPageData={getPageData}
                      />
                    </div>
                    </div>
                  </div>
                </div>
              </div>
            </div>
          )}
        </div>
      </div>
    </div>
  );
};

export default AssignInspector;
