import StudentLogin from "./Pages/StudentLogin";
import LecturerLogin from "./Pages/LecturerLogin";
import CoordinatorLogin from "./Pages/CoordinatorLogin";
import Register from "./Pages/Register";
import Verify from "./Pages/verifyStudent";
import Validate from "./Pages/validateOtp";
import ValidatePass from "./Pages/verifyStudentPass";
import ChangePass from "./Pages/changePassword";
import StudentIndex from "./Pages/Students/Index";
import StudentUpload from "./Pages/Students/Upload";
import UpdateDetails from "./Pages/Students/updateDetails";
import StudentReport from "./Pages/Students/Report";
import StudentPassword from "./Pages/Students/ChangePassword";
import StudentContact from "./Pages/Students/Contact";
import LecturerIndex from "./Pages/Lecturers/Index";
import LecturerPassword from "./Pages/Lecturers/ChangePassword";
import LecturerEdit from "./Pages/Lecturers/EditDetails";
import LecturerForms from "./Pages/Lecturers/Forms";
import LecturerInspection from "./Pages/Lecturers/Inspection";
import LecturerGradeInspection from "./Pages/Lecturers/GradeInspection";
import LecturerDefence from "./Pages/Lecturers/Defence";
import LecturerGradeDefence from "./Pages/Lecturers/GradeDefence"
import CoordinatorIndex from "./Pages/Coordinator/Index";
import CoordinatorPassword from "./Pages/Coordinator/ChangePassword";
import CoordinatorEdit from "./Pages/Coordinator/EditDetails";
import CoordinatorUpload from "./Pages/Coordinator/UploadForm";
import CreateCoordinator from "./Pages/Coordinator/CreateCoordinator";
import CoordinatorCreate from "./Pages/Coordinator/CreateSupervisor";
import CoordinatorAssignInspector from "./Pages/Coordinator/AssignInspector";
import CoordinatorAssignDefence from "./Pages/Coordinator/AssignDefence";
import CoordinatorAllSupervisors from "./Pages/Coordinator/RegisteredSupervisors";
import CoordinatorAllStudents from "./Pages/Coordinator/Students";
import CoordinatorWeeklyReports from "./Pages/Coordinator/WeeklyReports";
import CoordinatorDeadline from "./Pages/Coordinator/SetRegistrationDeadline";
import CoordinatorStudentDetails from "./Pages/Coordinator/StudentDetails";
import CoordinatorEditStudents from "./Pages/Coordinator/editStudents"
import CoordinatorCollateGrades from "./Pages/Coordinator/CollateGrades";
import BatchGrade from "./Pages/Coordinator/BatchGrade";
import TotalWeeklyReport from "./Pages/Coordinator/TotalWeeklyReport";
import Home from "./Pages/Home";
import { BrowserRouter, Route, Routes } from "react-router-dom";
import { useContext } from "react";
import {
  Context,
  ContextCoordinator,
  ContextSupervisor,
  // ContextValidation,
  ContextVerification
} from "./Context/Context";
import "./App.css";

function App() {
  const { student } = useContext(Context);
  const { coordinator } = useContext(ContextCoordinator);
  const { supervisor } = useContext(ContextSupervisor);
  // const { validation } = useContext(ContextValidation);
  const { verification } = useContext(ContextVerification);

  return (
    <BrowserRouter>
      <Routes>
        <Route exact path="/" element={<Home />} />
        <Route exact path="/studentlogin" element={<StudentLogin />} />
        <Route exact path="/lecturerlogin" element={<LecturerLogin />} />
        <Route exact path="/coordinatorlogin" element={<CoordinatorLogin />} />
        <Route exact path="/verifyStudent" element={<Verify />} />
        {/* <Route exact path="/validateOtp" element={<Validate/>} /> */}
        <Route exact path="/verifyStudentPass" element={<ValidatePass/>} />
        <Route exact path="/changePassword" element={<ChangePass/>} />

        <Route
          exact
          path="/Register"
          element={
            verification ? <Register verification={verification} /> : <Verify/>
          }
        />
        <Route
          exact
          path="/validateOtp"
          element={
            verification ? <Validate verification={verification} /> : <Verify/>
          }
        />

        <Route
          exact
          path="/students/index"
          element={
            student ? <StudentIndex student={student} /> : <StudentLogin />
          }
        />
        <Route
          exact
          path="/students/upload"
          element={
            student ? <StudentUpload student={student} /> : <StudentLogin />
          }
        />
        <Route
          exact
          path="/students/report"
          element={
            student ? <StudentReport student={student} /> : <StudentLogin />
          }
        />
        <Route
          exact
          path="/students/changepassword"
          element={
            student ? <StudentPassword student={student} /> : <StudentLogin />
          }
        />
        <Route
          exact
          path="/students/updateDetails"
          element={
            student ? <UpdateDetails student={student} /> : <StudentLogin />
          }
        />
        <Route
          exact
          path="/students/contact"
          element={
            student ? <StudentContact student={student} /> : <StudentLogin />
          }
        />
        <Route
          exact
          path="/students/message"
          element={
            student ? <StudentContact student={student} /> : <StudentLogin />
          }
        />
        <Route
          exact
          path="/lecturers/index"
          element={
            supervisor ? (
              <LecturerIndex supervisor={supervisor} />
            ) : (
              <LecturerLogin />
            )
          }
        />
        <Route
          exact
          path="/lecturers/changepassword"
          element={
            supervisor ? (
              <LecturerPassword supervisor={supervisor} />
            ) : (
              <LecturerLogin />
            )
          }
        />
        <Route
          exact
          path="/lecturers/editdetails"
          element={
            supervisor ? (
              <LecturerEdit supervisor={supervisor} />
            ) : (
              <LecturerLogin />
            )
          }
        />
        <Route
          exact
          path="/lecturers/forms"
          element={
            supervisor ? (
              <LecturerForms supervisor={supervisor} />
            ) : (
              <LecturerLogin />
            )
          }
        />
        <Route
          exact
          path="/lecturers/inspection"
          element={
            supervisor ? (
              <LecturerInspection supervisor={supervisor} />
            ) : (
              <LecturerLogin />
            )
          }
        />
        <Route
          exact
          path="/lecturers/gradeinspection"
          element={
            supervisor ? (
              <LecturerGradeInspection supervisor={supervisor} />
            ) : (
              <LecturerLogin />
            )
          }
        />
        <Route
          exact
          path="/lecturers/defence"
          element={
            supervisor ? (
              <LecturerDefence supervisor={supervisor} />
            ) : (
              <LecturerLogin />
            )
          }
        />
        <Route
          exact
          path="/lecturers/gradedefence"
          element={
            supervisor ? (
              <LecturerGradeDefence supervisor={supervisor} />
            ) : (
              <LecturerLogin />
            )
          }
        />
        <Route
          exact
          path="/coordinator/index"
          element={
            coordinator ? (
              <CoordinatorIndex coordinator={coordinator} />
            ) : (
              <CoordinatorLogin />
            )
          }
        />
        <Route
          exact
          path="/coordinator/changepassword"
          element={
            coordinator ? (
              <CoordinatorPassword coordinator={coordinator} />
            ) : (
              <CoordinatorLogin />
            )
          }
        />
        <Route
          exact
          path="/coordinator/editdetails"
          element={
            coordinator ? (
              <CoordinatorEdit coordinator={coordinator} />
            ) : (
              <CoordinatorLogin />
            )
          }
        />
        <Route
          exact
          path="/coordinator/uploadform"
          element={
            coordinator ? (
              <CoordinatorUpload coordinator={coordinator} />
            ) : (
              <CoordinatorLogin />
            )
          }
        />
        <Route
          exact
          path="/coordinator/createsupervisor"
          element={
            coordinator ? (
              <CoordinatorCreate coordinator={coordinator} />
            ) : (
              <CoordinatorLogin />
            )
          }
        />
        <Route
          exact
          path="/coordinator/createcoordinator"
          element={
            coordinator ? (
              <CreateCoordinator coordinator={coordinator} />
            ) : (
              <CoordinatorLogin />
            )
          }
        />
        <Route
          exact
          path="/coordinator/assigninspector"
          element={
            coordinator ? (
              <CoordinatorAssignInspector coordinator={coordinator} />
            ) : (
              <CoordinatorLogin />
            )
          }
        />
        <Route
          exact
          path="/coordinator/assigndefence"
          element={
            coordinator ? (
              <CoordinatorAssignDefence coordinator={coordinator} />
            ) : (
              <CoordinatorLogin />
            )
          }
        />
        <Route
          exact
          path="/coordinator/supervisors"
          element={
            coordinator ? (
              <CoordinatorAllSupervisors coordinator={coordinator} />
            ) : (
              <CoordinatorLogin />
            )
          }
        />
        <Route
          exact
          path="/coordinator/students"
          element={
            coordinator ? (
              <CoordinatorAllStudents coordinator={coordinator} />
            ) : (
              <CoordinatorLogin />
            )
          }
        />
        <Route
          exact
          path="/coordinator/setregistration"
          element={
            coordinator ? (
              <CoordinatorDeadline coordinator={coordinator} />
            ) : (
              <CoordinatorLogin />
            )
          }
        />
        <Route
          exact
          path="/coordinator/student/:id"
          element={
            coordinator ? (
              <CoordinatorStudentDetails coordinator={coordinator} />
            ) : (
              <CoordinatorLogin />
            )
          }
        />
        <Route
          exact
          path="/coordinator/edit/student/:id"
          element={
            coordinator ? (
              <CoordinatorEditStudents coordinator={coordinator} />
            ) : (
              <CoordinatorLogin />
            )
          }
        />
        <Route
          exact
          path="/coordinator/student/weeklyreports/:id"
          element={
            coordinator ? (
              <CoordinatorWeeklyReports coordinator={coordinator} />
            ) : (
              <CoordinatorLogin />
            )
          }
        />
        <Route
          exact
          path="/coordinator/batchgrading"
          element={
            coordinator ? (
              <BatchGrade coordinator={coordinator} />
            ) : (
              <CoordinatorLogin />
            )
          }
        />
        <Route
          exact
          path="/coordinator/totalweeklyreport"
          element={
            coordinator ? (
              <TotalWeeklyReport coordinator={coordinator} />
            ) : (
              <CoordinatorLogin />
            )
          }
        />
        <Route
          exact
          path="/coordinator/collategrades"
          element={
            coordinator ? (
              <CoordinatorCollateGrades coordinator={coordinator} />
            ) : (
              <CoordinatorLogin />
            )
          }
        />

        {/* Define a 404 route at the end */}
        <Route path="*" element={<Home />} />
      </Routes>
    </BrowserRouter>
  );
}

export default App;
