import React, { useEffect, useState } from "react";
import { useNavigate } from "react-router-dom";
import LecturerNav from "../../Components/LecturerNav";
import excl from "../../Icons/exclamation-lg.svg";
import tick from "../../Icons/check-lg.svg";
import axios from "../../api/axios";
import "../../CSS/Profile.css";

const EditDetails = () => {
  useEffect(() => {
    document.title = "Edit Details";
  }, []);

  const [firstName, setFirstName] = useState();
  const [lastName, setLastName] = useState();
  const [phone, setPhone] = useState();
  const [office, setOffice] = useState();
  const [errMessage, setErrMessage] = useState();
  const [uploadFail, setUploadFail] = useState();
  const [success, setSucess] = useState(false);
  const [loading, setLoading] = useState(false);
  const navigate = useNavigate();

  const handleSubmit = async (e) => {
    e.preventDefault();
    setUploadFail(false);
    setLoading(true);
    try {
      const res = await axios.patch(
        `/api/supervisor/update`,
        JSON.stringify({ firstName, lastName, phone, office }),
        {
          headers: { "Content-type": "application/json" }
        }
      );
      setSucess(true);
      setLoading(false);
      setUploadFail(false);
      setTimeout(() => {
        navigate("/lecturers/index");
      }, 2000);
    } catch (err) {
      setLoading(false);
      setUploadFail(true);
      setErrMessage(err.response.data.message);
    }
  };

  return (
    <div>
      <LecturerNav />
      <div className="page-wrapper">
        <div className="page-content">
          <div className="heading">
            <h2 className="profile-header">Edit Lecturer's Details</h2>
          </div>

          <div className="work-form-wrapper">
            <form action="" method="post" className="grid-small">
              <div className="work-form">
                <div className="work-form-half half-1">
                  <label htmlFor="first-name">
                    <span className="label">First Name</span>
                    <input
                      type="text"
                      name="first-name"
                      id="first-name"
                      className="work-form-input"
                      required
                      onChange={(e) => setFirstName(e.target.value)}
                    />
                  </label>

                  <label htmlFor="last-name">
                    <span className="label">Last Name</span>
                    <input
                      type="text"
                      name="last-name"
                      id="last-name"
                      className="work-form-input"
                      onChange={(e) => setLastName(e.target.value)}
                      required
                    />
                  </label>

                  <label htmlFor="email">
                    <span className="label">Email</span>
                    <input
                      type="email"
                      name="email"
                      id="email"
                      className="work-form-input input-disabled"
                      disabled
                    />
                  </label>
                </div>
                <div className="work-form-half half-2">
                  <label htmlFor="number1">
                    <span className="label">Phone Number</span>
                    <input
                      type="text"
                      name="number1"
                      id="number1"
                      className="work-form-input"
                      required
                      onChange={(e) => setPhone(e.target.value)}
                    />
                  </label>

                  <label htmlFor="office-location">
                    <span className="label">Office Location</span>
                    <input
                      type="text"
                      name="office-location"
                      id="office-location"
                      className="work-form-input"
                      required
                      onChange={(e) => setOffice(e.target.value)}
                    />
                  </label>
                </div>
              </div>
              <button className="submit-upload" onClick={handleSubmit}>
                {loading ? (
                  <div className="center-load">
                    <span className="basic"></span>
                  </div>
                ) : (
                  `Update Details`
                )}
              </button>
              <div className={uploadFail ? "post-fail" : ""}>
                {uploadFail ? (
                  <div className="result-icon">
                    <img src={excl} />
                    {errMessage}
                  </div>
                ) : (
                  ""
                )}
              </div>
              <div className={success ? "post-success" : ""}>
                {success ? (
                  <div className="result-icon">
                    <img src={tick} />
                    Details Updated Successfully!
                  </div>
                ) : (
                  ""
                )}
              </div>
            </form>
          </div>
        </div>
      </div>
    </div>
  );
};

export default EditDetails;
