import React, {useEffect} from "react";
import HomeNav from "../Components/HomeNav";
import HomeFooter from "../Components/HomeFooter";
import { Link } from "react-router-dom";
import logoColor from "../Images/logo_image_coloured.png";
import itfImg from "../Images/ITF_captioned.png";
import numOne from "../Icons/number1.svg"
import numTwo from "../Icons/number2.svg"
import numThree from "../Icons/number3.svg"
import numFour from "../Icons/number4.svg"
import "../CSS/Home.css";

const Home = () => {
  useEffect(() => {
    document.title = "Babcock University SIWES Home";
  }, []);
  return (
    <div>
      <HomeNav />
      <div className="content-container page">
        <h1 className="main-header herokun">Babcock University SIWES Portal</h1>

        <div className="hero-container">
          <img src={logoColor} alt="" className="hero-image" />
          <h3 className="hero-text">
            In <span className="hero-text__bold">collaboration</span> with
          </h3>
          <img src={itfImg} alt="" className="hero-image2" />
        </div>
        <br />

        <p className="content-description">
          The Student Industrial Work Experience Scheme (SIWES) program was
          established by the Industrial Training Fund (ITF) in 1973 and it is
          the accepted skill training program adopted by many Nigerian Tertiary
          Institutions. It forms part of the approved minimum academic standard
          in various degree program in these institutions for graduating its
          students. The SIWES program is an effort to bridge the gap that exists
          between theory and practice.
        </p>

        <h1 className="header">
          The <span className="header__span">Aims</span> &{" "}
          <span className="header__span">Objectives</span> of the SIWES Program
        </h1>

        <p className="content-description">
          The main aim to be achieved during the SIWES program is the ability to
          practice the knowledge gained in the school room into experience
          gained on the industrial field within the range of the student's
          course of study. The key objectives are;
        </p>

        <div className="obj-container">
          <div className="obj">
            <img src={numOne} alt="" />
            <p className="obj-description">
              Provide students with an opportunity to apply their theoretical
              knowledge gained in the school room in real work situations on the
              field.
            </p>
          </div>

          <div className="obj">
            <img src={numTwo} alt="" />
            <p className="obj-description">
              To create a better and easier transmission for the student, from
              university students, to working class adults. Thus enhancing the
              student's contacts for a later job placement.
            </p>
          </div>

          <div className="obj">
            <img src={numThree} alt="" />
            <p className="obj-description">
              To give room for social and academic interaction with
              professionals in their fields and working environments and also to
              expose the reality of working places.
            </p>
          </div>

          <div className="obj">
            <img src={numFour} alt="" />
            <p className="obj-description">
              To imbibe into each student the ability to create a good work
              relationship with their present student colleagues and also their
              future work colleagues.
            </p>
          </div>
        </div>

        <div className="activities--container">
          <h1 className="header">SIWES Program Activities</h1>

          <div className="wrapper">
            <div className="center-line"></div>

            <div className="row row-1">
              <section>
                <i className="icon bi bi-file-text-fill"></i>
                <div className="details">
                  <span className="title">Step 1</span>
                </div>
                <p>
                  Collect your Letter of Introduction, Log Book, ITF Form & SCAF
                  form from your SIWES Coordinator
                </p>
              </section>
            </div>

            <div className="row row-2">
              <section>
                <i className="icon bi bi-file-check-fill"></i>
                <div className="details">
                  <span className="title">Step 2</span>
                </div>
                <p>Collect your Acceptance Letter from your chosen company</p>
              </section>
            </div>

            <div className="row row-1">
              <section>
                <i className="icon bi bi-journals"></i>
                <div className="details">
                  <span className="title">Step 3</span>
                </div>
                <p>
                  Submit a copy of your Letter of Acceptance to your SIWES
                  Coordinator after it has been signed by your chosen company
                </p>
              </section>
            </div>

            <div className="row row-2">
              <section>
                <i className="icon bi bi-pencil-square"></i>
                <div className="details">
                  <span className="title">Step 4</span>
                </div>
                <p>
                  Register as a valid SIWES Student on this website{" "}
                  <Link to="/register" className="reg">
                    here
                  </Link>
                </p>
              </section>
            </div>

            <div className="row row-1">
              <section>
                <i className="icon bi bi-file-check-fill"></i>
                <div className="details">
                  <span className="title">Step 5</span>
                </div>
                <p>
                  Submit your SCAF form to the nearest ITF office to your
                  workplace within the first two weeks of your resumption at your workplace
                </p>
              </section>
            </div>
 
            <div className="row row-2">
              <section>
                <i className="icon bi bi-journal-check"></i>
                <div className="details">
                  <span className="title">Step 6</span>
                </div>
                <p>
                  Submit your reports every weekday, througout the
                  duration of your IT period using the student portal on this
                  website
                </p>
              </section>
            </div>

            <div className="row row-1">
              <section>
                <i className="icon bi bi-book-half"></i>
                <div className="details">
                  <span className="title">Step 7</span>
                </div>
                <p>
                  At the end of your IT period, take your Log Book and ITF
                  Form 8 for approval, signing and stamping at the nearest ITF
                  office to your workplace
                </p>
              </section>
            </div>

            <div className="row row-2">
              <section>
                <i className="icon bi bi-journal-check"></i>
                <div className="details">
                  <span className="title">Step 8</span>
                </div>
                <p>
                  Submit the Hard Copy of your Log Book, IT Report & ITF Form 8 to
                  your Coordinator duly signed by the intended parties
                </p>
              </section>
            </div>

            <div className="row row-1">
              <section>
                <i className="icon bi bi-person-fill"></i>
                <div className="details">
                  <span className="title">Step 9</span>
                </div>
                <p>Oral Defence of IT with your assigned Defence Supervisor</p>
              </section>
            </div>
          </div>
        </div>

        <HomeFooter />
      </div>
    </div>
  );
};

export default Home;
