import React from "react";

const Pagination = ({
  totalPosts,
  postsPerPage,
  setCurrentPage,
  currentPage,
  getPageData
}) => {
  let pages = [];

  for (let i = 1; i <= Math.ceil(totalPosts / postsPerPage); i++) {
    pages.push(i);
  }

  const checkHidden = (page, currentPage, pages) => {
    if (page === currentPage) {
      return false;
    }

    if (page === 1 || page === pages.length) {
      return false;
    }

    if (page === currentPage - 1 || page === currentPage + 1) {
      return false;
    }

    return true;
  };

  return (
    <div className="pagination">
      {pages.map((page, index) => {
        return (
          <button
            key={index}
            onClick={() => {
              setCurrentPage(page)
              getPageData(page)
            }}
            className={
              page === currentPage ? "pagination-btn active" : "pagination-btn"
            }
            hidden={checkHidden(page, currentPage, pages)}
          >
            {page}
          </button>
        );
      })}
    </div>
  );
};

export default Pagination;
