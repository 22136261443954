import React, { useEffect, useState } from "react";
import LecturerNav from "../../Components/LecturerNav";
import axios from "../../api/axios";
import "../../CSS/Profile.css";
import "../../CSS/Table.css";

const Forms = () => {
  useEffect(() => {
    document.title = "View Inspection/Defence Forms";
  }, []);

  const [formInfo, setFormInfo] = useState();
  const [loading, setLoading] = useState(true);
  const [loadFail, setLoadFail] = useState(false);
  const [errMessage, setErrMessage] = useState();

  useEffect(() => {
    setLoadFail(false);
    const fetchData = async () => {
      try {
        const res = await axios.get(`/api/supervisor/forms`);
        setFormInfo(res.data);
        setLoading(false);
      } catch (error) {
        setLoadFail(true);
        setLoading(false);
        setErrMessage(error.response.data.message);
      }
    };
    fetchData();
  }, []);

  return (
    <div>
      <LecturerNav />
      <div className="page-wrapper">
        <div className="page-content">
          <div className="heading">
            <h2 className="profile-header">View Inspection/Defence Forms</h2>
          </div>
          {loading ? (
            <div className="center-load">
              <span className="basic big"></span>
            </div>
          ) : loadFail ? (
            <h2 className="profile-header">{errMessage}</h2>
          ) : (
            <div className="loaded">
              <div className="limiter">
                <div className="container-table100">
                  <div className="wrap-table100">
                    <div className="table100">
                      <table>
                        <thead>
                          <tr className="table100-head">
                            <th className="column1">Form Type</th>
                            <th className="column2">Form Description</th>
                            <th className="column3">Form Document</th>
                          </tr>
                        </thead>
                        <tbody>
                          {formInfo.map((form) => {
                            return (
                              <tr key={form._id}>
                                <td className="column1 capitalize">
                                  {form.purpose}
                                </td>
                                <td className="column2">{form.description}</td>
                                <td className="column3">
                                  <a
                                    href={`${form.pathToFile}`}
                                    download
                                    className="reg"
                                  >
                                    {form.name}
                                  </a>
                                </td>
                              </tr>
                            );
                          })}
                        </tbody>
                      </table>
                    </div>
                  </div>
                </div>
              </div>
            </div>
          )}
        </div>
      </div>
    </div>
  );
};

export default Forms;
