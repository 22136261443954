import React, { useEffect, useState } from "react";
import { useParams } from "react-router-dom";
import CoordinatorNav from "../../Components/CoordinatorNav";
import axios from "../../api/axios";
import "../../CSS/Profile.css";

const WeeklyReports = () => {
  useEffect(() => {
    document.title = "Weekly Reports";
  }, []);

  const [studentReports, setStudentReports] = useState();
  const [loading, setLoading] = useState(true);
  const [company, setCompany] = useState();
  const [loadFail, setLoadFail] = useState(false);
  const [errMessage, setErrMessage] = useState();
  const { id } = useParams();

  useEffect(() => {
    setLoadFail(false);
    const fetchReport = async () => {
      try {
        const res = await axios.get(`/api/coordinators/getWeeklyReports/${id}`);
        setStudentReports(res.data.reports);
        setLoading(false);
      } catch (error) {
        setLoadFail(true);
        setLoading(false);
        setErrMessage(error.response.data.message);
      }
    };
    fetchReport();
  }, []);

  return (
    <div>
      <CoordinatorNav />
      <div className="page-wrapper">
        <div className="page-content">
          {loading ? (
            <div className="center-load">
              <h3 className="basic big"></h3>
            </div>
          ) : loadFail ? (
            <h2 className="profile-header">{errMessage}</h2>
          ) : (
            <div className="loaded">
              <div className="heading">
                <h2 className="profile-header">Weekly Reports</h2>
              </div>

              <div className="weekly-report-details">
                {studentReports.map((report) => {
                  return (
                    <div key={report._id}>
                      <h2 className="reg-wk">
                        Week Started:{" "}
                        {new Date(report.weekStart).toLocaleDateString()}
                      </h2>
                      <h3 className="reg">Monday</h3>
                      <p className="details-report">{report.monday}</p>

                      <h3 className="reg">Tuesday</h3>
                      <p className="details-report">{report.tuesday}</p>

                      <h3 className="reg">Wednesday</h3>
                      <p className="details-report">{report.wednesday}</p>

                      <h3 className="reg">Thursday</h3>
                      <p className="details-report">{report.thursday}</p>

                      <h3 className="reg">Friday</h3>
                      <p className="details-report">{report.friday}</p>
                    </div>
                  );
                })}
              </div>
            </div>
          )}
        </div>
      </div>
    </div>
  );
};

export default WeeklyReports;
