import React from "react";
import ReactDOM from "react-dom";
import "./index.css";
import App from "./App";
import {
  ContextProvider,
  ContextProviderCoordinator,
  ContextProviderSupervisor,
  ContextProviderVerification,
  // ContextProviderValidation
} from "./Context/Context";

ReactDOM.render(
  <React.StrictMode>
    <ContextProvider>
      <ContextProviderCoordinator>
        <ContextProviderSupervisor>
          <ContextProviderVerification>
            {/* <ContextProviderValidation> */}
              <App/>
            {/* </ContextProviderValidation> */}
          </ContextProviderVerification>
        </ContextProviderSupervisor>
      </ContextProviderCoordinator>
    </ContextProvider>
  </React.StrictMode>,
  document.getElementById("root")
);
