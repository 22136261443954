import React, { useEffect, useState } from "react";
import { Link } from "react-router-dom";
import axios from "../../api/axios";
import { CSVLink } from "react-csv";
import CoordinatorNav from "../../Components/CoordinatorNav";
import csvIcon from "../../Icons/filetype-csv.svg";
import "../../CSS/Profile.css";

const CollateGrades = () => {
  useEffect(() => {
    document.title = "Collate Grades";
  }, []);

  const [loading, setLoading] = useState(true);
  const [submitLoading, setSubmitLoading] = useState(false);
  const [loadFail, setLoadFail] = useState(false);
  const [errMessage, setErrMessage] = useState();
  const [studentData, setStudentData] = useState();
  const [success, setSucess] = useState(false);
  const [regFail, setRegFail] = useState(false);
  const [csvData, setCsvData] = useState([]);

  useEffect(() => {
    setLoadFail(false);
    const fetchData = async () => {
      try {
        const res = await axios.get(`/api/coordinators/students`);
        setStudentData(res.data.students);
        for (let student of res.data.students) {
          setCsvData((curCsv) => [
            ...curCsv,
            {
              Name: `${student.lastName} ${student.firstName} ${student.middleName}`,
              MatricNo: student.matricNo,
              Course: student.course,
              WeeklyReportGrade: student.grade
                ? student.grade.inspectionScore
                : "N/A",
              InspectionGrade: student.grade
                ? student.grade.inspectionScore
                : "N/A",
              DefenceGrade: student.grade ? student.grade.defenseScore : "N/A",
              TotalGrade: student.grade ? student.grade.total : "N/A"
            }
          ]);
        }
        setLoading(false);
      } catch (error) {
        setLoadFail(true);
        setLoading(false);
        setErrMessage(error.response.data.message);
      }
    };
    fetchData();
  }, []);

  const handleSubmit = async (e) => {
    e.preventDefault();
    setSubmitLoading(true);
    setRegFail(false);
    try {
      const res = await axios.patch(`/api/coordinators/collateAllGrades`, {
        headers: { "Content-type": "application/json" }
      });
      setSucess(true);
      setSubmitLoading(false);
      setRegFail(false);
      setTimeout(() => {
        setSucess(false);
      }, 3000);
    } catch (err) {
      setRegFail(true);
      setSubmitLoading(false);
      err.response.data.errors
        ? setErrMessage(err.response.data.errors)
        : setErrMessage(err.response.data.message);
    }
  };

  return (
    <div>
      <CoordinatorNav />
      <div className="page-wrapper">
        <div className="page-content">
          <div className="heading">
            <h2 className="profile-header">Collate Grades</h2>
          </div>
          {loading ? (
            <div className="center-load">
              <span className="basic big"></span>
            </div>
          ) : loadFail ? (
            <h2 className="profile-header">{errMessage}</h2>
          ) : (
            <div className="limiter loaded">
              <CSVLink
                className="export"
                data={csvData}
                filename={"Student-Grades.csv"}
              >
                <img src={csvIcon} alt="csv" />
                Export
              </CSVLink>
              <div className="container-table100">
                <div className="wrap-table100">
                  <div className="table100">
                    <table className="co-students">
                      <thead>
                        <tr className="table100-head">
                          <th className="column4">Student Name</th>
                          <th className="column5">Matric Number</th>
                          <th className="column4">Course</th>
                          <th className="column4">Weekly Report Grade</th>
                          <th className="column4">Inspection Grade</th>
                          <th className="column4">Defence Grade</th>
                          <th className="column4">Total Grade</th>
                        </tr>
                      </thead>
                      <tbody>
                        {studentData.map((student) => {
                          return (
                            <tr key={student._id}>
                              <td className="column6 capitalize">
                                <Link
                                  to={`/coordinator/student/${student._id}`}
                                  className="reg"
                                >{`${student.lastName} ${student.firstName} ${
                                  student.middleName ? student.middleName : ""
                                }`}</Link>
                              </td>
                              <td className="column5">{student.matricNo}</td>
                              <td className="column4 capitalize">
                                {student.course}
                              </td>
                              <td className="column4">
                                {student.grade
                                  ? student.grade.weeklyReportsScore
                                  : "N/A"}
                              </td>
                              <td className="column4">
                                {student.grade
                                  ? student.grade.inspectionScore
                                  : "N/A"}
                              </td>
                              <td className="column4">
                                {student.grade
                                  ? student.grade.defenseScore
                                  : "N/A"}
                              </td>
                              <td className="column4">
                                {student.grade ? student.grade.total : "N/A"}
                              </td>
                            </tr>
                          );
                        })}
                      </tbody>
                    </table>
                    <button className="submit-upload" onClick={handleSubmit}>
                      {submitLoading ? (
                        <div className="center-load">
                          <span className="basic"></span>
                        </div>
                      ) : (
                        `Collate Grades`
                      )}
                    </button>
                    <div className={regFail ? "post-fail" : ""}>
                      {regFail ? (
                        <div className="result-icon">{errMessage}</div>
                      ) : (
                        ""
                      )}
                    </div>
                    <div className={success ? "post-success" : ""}>
                      {success ? (
                        <div className="result-icon">
                          Collated Successfully!
                        </div>
                      ) : (
                        ""
                      )}
                    </div>
                  </div>
                </div>
              </div>
            </div>
          )}
        </div>
      </div>
    </div>
  );
};

export default CollateGrades;
