import React, { useEffect, useState } from "react";
import { useParams, Link } from "react-router-dom";
import CoordinatorNav from "../../Components/CoordinatorNav";
import axios from "../../api/axios";
import "../../CSS/Profile.css";
import excl from "../../Icons/exclamation-lg.svg";
import tick from "../../Icons/check-lg.svg";

const StudentDetails = () => {
  useEffect(() => {
    document.title = "Student Details";
  }, []);

  const [studentInfo, setStudentInfo] = useState();
  const [score, setScore] = useState();
  const [type, setType] = useState();
  const [studentId, setStudentId] = useState();
  const [loading, setLoading] = useState(true);
  const [company, setCompany] = useState();
  const [loadFail, setLoadFail] = useState(false);
  const [errMessage, setErrMessage] = useState();
  const [success, setSucess] = useState(false);
  const [regFail, setRegFail] = useState(false);
  const [collateSuccess, setCollateSucess] = useState(false);
  const [collateFail, setCollateFail] = useState(false);
  const [submitLoading, setSubmitLoading] = useState(false);
  const [collateLoading, setCollateLoading] = useState(false);
  const { id } = useParams();

  useEffect(() => {
    setLoadFail(false);
    const fetchData = async () => {
      try {
        const res = await axios.get(`/api/coordinators/students/${id}`);
        setStudentInfo(res.data[0]);
        setCompany(res.data[0].company);
        setLoading(false);
      } catch (error) {
        setLoadFail(true);
        setLoading(false);
        setErrMessage(error.response.data.message);
      }
    };
    fetchData();
  }, []);

  const handleSubmit = async (e) => {
    e.preventDefault();
    setSubmitLoading(true);
    setRegFail(false);
    try {
      const res = await axios.post(
        `/api/coordinators/assignGrade`,
        JSON.stringify({ studentId, type, score }),
        {
          headers: { "Content-type": "application/json" }
        }
      );
      setSucess(true);
      setSubmitLoading(false);
      setRegFail(false);
      setTimeout(() => {
        setSucess(false);
      }, 3000);
    } catch (err) {
      setRegFail(true);
      setSubmitLoading(false);
      err.response.data.errors
        ? setErrMessage(err.response.data.errors)
        : setErrMessage(err.response.data.message);
    }
  };

  const handleSubmitGrade = async (e) => {
    e.preventDefault();
    setCollateLoading(true);
    setCollateFail(false);
    try {
      const res = await axios.patch(`/api/coordinators/collateGrades/${id}`, {
        headers: { "Content-type": "application/json" }
      });
      setCollateSucess(true);
      setCollateLoading(false);
      setCollateFail(false);
      setTimeout(() => {
        setCollateSucess(false);
      }, 3000);
    } catch (err) {
      setCollateFail(true);
      setCollateLoading(false);
      err.response.data.errors
        ? setErrMessage(err.response.data.errors)
        : setErrMessage(err.response.data.message);
    }
  };

  return (
    <div>
      <CoordinatorNav />
      <div className="page-wrapper">
        <div className="page-content">
          {loading ? (
            <div className="center-load">
              <span className="basic big"></span>
            </div>
          ) : loadFail ? (
            <h2 className="profile-header">{errMessage}</h2>
          ) : (
            <div className="loaded">
              <div className="heading">
                <h2 className="profile-header">Student Details</h2>
              </div>

              <div className="dashboard-details">
                <div className="details-half">
                  <h2 className="details-heading">School Details</h2>

                  <div className="details-content">
                    <p className="details-title">Name</p>
                    <p className="details-name capitalize">
                      {studentInfo.name}
                    </p>
                  </div>

                  <div className="details-content">
                    <p className="details-title">Student Code</p>
                    <p className="details-name">{studentInfo.studentCode}</p>
                  </div>

                  <div className="details-content">
                    <p className="details-title">Matric Number</p>
                    <p className="details-name">{studentInfo.matricNo}</p>
                  </div>

                  <div className="details-content">
                    <p className="details-title">Level | Sex</p>
                    <p className="details-name">
                      {studentInfo.level} | {studentInfo.sex}
                    </p>
                  </div>

                  <div className="details-content">
                    <p className="details-title">Course</p>
                    <p className="details-name capitalize">
                      {studentInfo.course}
                    </p>
                  </div>

                  <div className="details-content">
                    <p className="details-title">Email</p>
                    <p className="details-name">{studentInfo.email}</p>
                  </div>

                  <div className="details-content">
                    <p className="details-title">Phone Number</p>
                    <p className="details-name">{studentInfo.phone}</p>
                  </div>
                </div>

                <div className="details-half">
                  <h2 className="details-heading">Work Details</h2>

                  <div className="details-content">
                    <p className="details-title">Company Name</p>
                    <p className="details-name capitalize">
                      {company ? company.name : "N/A"}
                    </p>
                  </div>
                  <div className="details-content">
                    <p className="details-title">Full company address</p>
                    <p className="details-name capitalize">
                      {company ? company.address : "N/A"}
                    </p>
                  </div>
                  <div className="details-content">
                    <p className="details-title">State | LGA</p>
                    <p className="details-name capitalize">
                      {company ? company.state : "N/A"} |
                      {company ? company.LGA : "N/A"}
                    </p>
                  </div>
                  <div className="details-content">
                    <p className="details-title">Office Email Address</p>
                    <p className="details-name">
                      {company ? company.email : "N/A"}
                    </p>
                  </div>
                  <div className="details-content">
                    <p className="details-title">Office Phone Number</p>
                    <p className="details-name">
                      {company ? company.phone : "N/A"}
                    </p>
                  </div>
                  <div className="details-content">
                    <p className="details-title">Assigned Department</p>
                    <p className="details-name capitalize">
                      {company ? company.assignedDepartment : "N/A"}
                    </p>
                  </div>

                  <div className="details-content">
                    <p className="details-title">Job Description</p>
                    <p className="details-name capitalize2">
                      {company ? company.assignedDepartment : "N/A"}
                    </p>
                  </div>
                  <div className="details-content">
                    <p className="details-title">Resumption Date(DD/MM/YYYY)</p>
                    <p className="details-name">
                      {company
                        ? new Date(company.resumptionDate).toLocaleDateString()
                        : "N/A"}
                    </p>
                  </div>
                  <div className="details-content">
                    <p className="details-title">
                      Termination Date(DD/MM/YYYY)
                    </p>
                    <p className="details-name">
                      {company
                        ? new Date(company.expectedEndDate).toLocaleDateString()
                        : "N/A"}
                    </p>
                  </div>
                </div>
              </div>
              <div className="weekly-report-details">
                {/* <h1 className="profile-header">Reset Password</h1>
                <input
                  type="password"
                  name="password"
                  id="password"
                  placeholder=""
                  className="work-form-input"
                /> */}

                <h2 className="profile-header capitalize">
                  <Link
                    to={`/coordinator/student/weeklyreports/${studentInfo.studentCode}`}
                    className="reg"
                  >
                    View {studentInfo.name}
                    <span className="noCapitalize">'s</span> Weekly Reports
                  </Link>
                </h2>

                <div className="grade-block">
                  <label htmlFor="weekly-report-grade">
                    <span className="label">Weekly Report Score</span>
                    <input
                      type="text"
                      name="weekly-report-grade"
                      id="weekly-report-grade"
                      className="work-form-input2"
                      onChange={(e) => {
                        setScore(e.target.value);
                        setStudentId(studentInfo._id);
                        setType("reports");
                      }}
                    />
                  </label>
                </div>
                <button className="submit-upload" onClick={handleSubmit}>
                  {type === "reports" && submitLoading ? (
                    <div className="center-load">
                      <span className="basic"></span>
                    </div>
                  ) : (
                    `Grade Weekly Report`
                  )}
                </button>
                <div
                  className={type === "reports" && regFail ? "post-fail" : ""}
                >
                  {type === "reports" && regFail ? (
                    <div className="result-icon">
                      <img src={excl} />
                      {errMessage}
                    </div>
                  ) : (
                    ""
                  )}
                </div>
                <div
                  className={
                    type === "reports" && success ? "post-success" : ""
                  }
                >
                  {type === "reports" && success ? (
                    <div className="result-icon">
                      <img src={tick} />
                      Graded Successfully!
                    </div>
                  ) : (
                    ""
                  )}
                </div>
                <div className="grade-label">
                  <label htmlFor="inspection-grade">
                    <span className="label">Inspection Score</span>

                    <input
                      type="text"
                      name="inspection-grade"
                      id="inspection-grade"
                      className="work-form-input2"
                      onChange={(e) => {
                        setScore(e.target.value);
                        setStudentId(studentInfo._id);
                        setType("inspection");
                      }}
                    />
                  </label>
                </div>
                <button className="submit-upload" onClick={handleSubmit}>
                  {type === "inspection" && submitLoading ? (
                    <div className="center-load">
                      <span className="basic"></span>
                    </div>
                  ) : (
                    `Grade Inspection`
                  )}
                </button>
                <div
                  className={
                    type === "inspection" && regFail ? "post-fail" : ""
                  }
                >
                  {type === "inspection" && regFail ? (
                    <div className="result-icon">
                      <img src={excl} />
                      {errMessage}
                    </div>
                  ) : (
                    ""
                  )}
                </div>
                <div
                  className={
                    type === "inspection" && success ? "post-success" : ""
                  }
                >
                  {type === "inspection" && success ? (
                    <div className="result-icon">
                      <img src={tick} />
                      Graded Successfully!
                    </div>
                  ) : (
                    ""
                  )}
                </div>
                <div className="grade-block">
                  <label htmlFor="defence-grade">
                    <span className="label">Defence Score</span>
                    <input
                      type="text"
                      name="defence-grade"
                      id="defence-grade"
                      className="work-form-input2"
                      onChange={(e) => {
                        setScore(e.target.value);
                        setStudentId(studentInfo._id);
                        setType("defense");
                      }}
                    />
                  </label>
                </div>
                <button className="submit-upload" onClick={handleSubmit}>
                  {type === "defense" && submitLoading ? (
                    <div className="center-load">
                      <span className="basic"></span>
                    </div>
                  ) : (
                    `Grade Defence`
                  )}
                </button>
                <div
                  className={type === "defense" && regFail ? "post-fail" : ""}
                >
                  {type === "defense" && regFail ? (
                    <div className="result-icon">
                      <img src={excl} />
                      {errMessage}
                    </div>
                  ) : (
                    ""
                  )}
                </div>
                <div
                  className={
                    type === "defense" && success ? "post-success" : ""
                  }
                >
                  {type === "defense" && success ? (
                    <div className="result-icon">
                      <img src={tick} />
                      Graded Successfully!
                    </div>
                  ) : (
                    ""
                  )}
                </div>
                <h2 className="details-heading">Collate Grades</h2>
                <button className="submit-upload" onClick={handleSubmitGrade}>
                  {collateLoading ? (
                    <div className="center-load">
                      <span className="basic"></span>
                    </div>
                  ) : (
                    `Collate Grades`
                  )}
                </button>
                <div className={collateFail ? "post-fail" : ""}>
                  {collateFail ? (
                    <div className="result-icon">
                      <img src={excl} />
                      {errMessage}
                    </div>
                  ) : (
                    ""
                  )}
                </div>
                <div className={collateSuccess ? "post-success" : ""}>
                  {collateSuccess ? (
                    <div className="result-icon">
                      <img src={tick} />
                      Collated Successfully!
                    </div>
                  ) : (
                    ""
                  )}
                </div>
                <br />
                <br />
              </div>
            </div>
          )}
        </div>
      </div>
    </div>
  );
};

export default StudentDetails;
