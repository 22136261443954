import React, { useEffect, useState } from "react";
import { useParams} from "react-router-dom";
import CoordinatorNav from "../../Components/CoordinatorNav";
import axios from "../../api/axios";
import "../../CSS/Profile.css";
// import excl from "../../Icons/exclamation-lg.svg";
// import tick from "../../Icons/check-lg.svg";

const StudentDetails = () => {
  useEffect(() => {
    document.title = "Edit Student Details";
  }, []);
  const [success, setSuccess] = useState(false);
  const [updateFail, setUpdateFail] = useState(false);
  const [studentInfo, setStudentInfo] = useState();
  const [firstName, setFirstName] = useState()
  const [middleName, setMiddleName] = useState()
  const [lastName, setLastName] = useState()
  const [course, setCourse] = useState()
  const [sex, setSex] = useState()
  const [level, setLevel] = useState()
  const [faculty, setFaculty] = useState()
  // const [matNo, setMatNo] = useState()
  const [company, setCompany] = useState(
    {
    name : '',
    address : '',
    state : '',
    LGA : '',
    email : '',
    phone : '',
    assignedDepartment : '',
    jobDescription : '',
    resumptionDate : '',
    expectedEndDate : ''
    }
  )
  const [loading, setLoading] = useState(true);
  const [loadingData, setLoadingData] = useState(false)
  const [loadFail, setLoadFail] = useState(false);
  const [errMessage, setErrMessage] = useState();
  const [successMsg, setSuccessMsg] = useState();
  const {id } = useParams();

  useEffect(() => {
    setLoadFail(false);
    const fetchData = async () => {
      try {
        const res = await axios.get(`/api/coordinators/students/${id}`);
        setStudentInfo(res.data[0]);
        setLoading(false);
      } catch (error) {
        setLoadFail(true);
        setLoading(false);
        setErrMessage(error.response.data.message);
      }
    };
    fetchData();
  }, []);
  const payload = {
    firstName,
    middleName,
    lastName,
    course,
    sex,
    level,
    faculty,
    company: {},
};

Object.entries(company).forEach(([key, value]) => {
  if (value) {
    payload.company[key] = value;
  }
});
const handleSubmit = async (e) => {
    e.preventDefault();
    setLoadingData(true)
  
    try {
      const res = await axios.patch(`/api/coordinators/students/${id}`, 
         payload
      ,{
        headers: { "Content-Type": "application/json" },
      });
  
  
      if (res.status === 200) {
        setSuccess(true)
        setSuccessMsg(res.data.message)
        setLoadingData(false);
      } else {
        setLoadingData(false);
        setErrMessage(res.message) 
        setUpdateFail(true)
      }
    } catch (error) {
      setUpdateFail(true)
      setErrMessage(error.response.data.message)
      setLoadingData(false);
    }
  };
  

  return (
    <div>
      <CoordinatorNav />
      <div className="page-wrapper">
        <div className="page-content">
          {loading ? (
            <div className="center-load">
              <span className="basic big"></span>
            </div>
          ) : loadFail ? (
            <h2 className="profile-header">{errMessage}</h2>
          ) : (
            <div className="loaded">
              <div className="heading">
                <h2 className="profile-header">Edit Student Details</h2>
                <p>Student name: {studentInfo.name}</p>
              </div>
                <form>

              <div className="dashboard-details">
                <div className="details-half">
                  <h2 className="details-heading">School Details</h2>

                  <div className="details-content">
                    <p className="details-title">FirstName</p>
                    <input type="text" className="details-name edit-field capitalize"
                     
                      onChange={ (e) => setFirstName(e.target.value) }
                    />
                  </div>
                  <div className="details-content">
                    <p className="details-title">Middle Name</p>
                    <input type="text" className="details-name edit-field capitalize"
                      
                      onChange={ (e) => setMiddleName(e.target.value) }
                    />
                  </div>
                  <div className="details-content">
                    <p className="details-title">Last Name</p>
                    <input type="text" className="details-name edit-field capitalize"
                      
                      onChange={ (e) => setLastName(e.target.value) }
                    />
                  </div>

                  <div className="details-content">
                    <p className="details-title">Student Code</p>
                    <input 
                        type="text" 
                        className="details-name edit-field capitalize"
                        disabled
                        value={studentInfo.studentCode}
                    />
                  </div>

                  <div className="details-content">
                    <p className="details-title">Matric Number</p>
                    <input 
                        type="text" 
                        disabled
                        className="details-name edit-field capitalize"
                        value={studentInfo.matricNo}
                        // onChange={(e) => setMatNo(e.target.value)}
                    />
                  </div>

                  <div className="details-content">
                    <p className="details-title">Level</p>
                    <input 
                        type="text" 
                        className="details-name edit-field capitalize"
                        placeholder={studentInfo.level} 
                        onChange={(e) => setLevel(e.target.value)}
                    />
                  </div>
                  <div className="details-content">
                    <p className="details-title">Sex</p>
                    <input type="text" className="details-name edit-field capitalize"
                      placeholder={studentInfo.sex}
                      onChange={(e) => setSex(e.target.value)}
                    />
                  </div>

                  <div className="details-content">
                    <p className="details-title">Course</p>
                    <input type="text" className="details-name edit-field capitalize"
                      placeholder={studentInfo.course}
                      onSubmit={(e) => setCourse(e.target.value)}
                    />
                  </div>

                  <div className="details-content">
                    <p className="details-title">Email</p>
                    <input 
                        type="text" 
                        className="details-name edit-field capitalize"
                        value={studentInfo.email}
                        disabled
                        />
                  </div>

                  <div className="details-content">
                    <p className="details-title">Phone Number</p>
                    <input 
                    type="text" 
                    className="details-name edit-field capitalize"
                    disabled
                    value={studentInfo.phone}
                    />
                  </div>
                </div>

                <div className="details-half">
                  <h2 className="details-heading">Work Details</h2>

                  <div className="details-content">
                    <p className="details-title">Company Name</p>
                    <input 
                      type="text" 
                      className="details-name edit-field capitalize"
                      placeholder={studentInfo.company ? studentInfo.company.name : "N/A"}
                      onChange={(e) => setCompany({
                        ...company, name: e.target.value
                      })}
                    />

                  </div>
                  <div className="details-content">
                    <p className="details-title">Full company address</p>
                    <input 
                    type="text" 
                    className="details-name edit-field capitalize"
                    placeholder={studentInfo.company ? studentInfo.company.address : "N/A"}
                    onChange={(e) => setCompany({...company, address: e.target.value})}
                    />
                  </div>
                  <div className="details-content">
                    <p className="details-title">State</p>
                    <input 
                    type="text" 
                    className="details-name edit-field capitalize"
                      placeholder={studentInfo.company ? studentInfo.company.state : "N/A"}
                      onChange={(e) => setCompany({...company, state:e.target.value})}
                    />
                  </div>
                  <div className="details-content">
                    <p className="details-title">LGA</p>
                    <input 
                    type="text" 
                    className="details-name edit-field capitalize"
                    placeholder={studentInfo.company ? studentInfo.company.LGA : "N/A"}
                    onChange={(e) => setCompany({ ...company, LGA : e.target.value})}
                    />
                  </div>
                  <div className="details-content">
                    <p className="details-title">Office Email Address</p>
                    <input 
                    type="text" 
                    className="details-name edit-field capitalize"
                    placeholder={studentInfo.company ? studentInfo.company.email : "N/A"}
                    onChange={(e) => setCompany({...company, email : e.target.value})}
                    />
                  </div>
                  <div className="details-content">
                    <p className="details-title">Office Phone Number</p>
                    <input 
                    type="text" 
                    className="details-name edit-field capitalize"
                    placeholder={studentInfo.company ? studentInfo.company.phone : "N/A"}
                    onChange={(e) => setCompany({...company, phone : e.target.value})}
                    />
                  </div>
                  <div className="details-content">
                    <p className="details-title">Assigned Department</p>
                    <input 
                    type="text" 
                    className="details-name edit-field capitalize"
                    placeholder={studentInfo.company ? studentInfo.company.assignedDepartment : "N/A"}
                    onChange={(e) => setCompany({...company, assignedDepartment : e.target.value})}
                    />
                  </div>

                  <div className="details-content">
                    <p className="details-title">Job Description</p>
                    <input 
                    type="text" 
                    className="details-name edit-field capitalize"
                    placeholder={studentInfo.company ? studentInfo.company.jobDescription : "N/A"}
                    onChange={(e) => setCompany({...company, jobDescription : e.target.value})}
                    />
                  </div>
                  <div className="details-content">
                    <p className="details-title">Resumption Date(MM/DD/YYYY)</p>
                    <input 
                    type="date" 
                    className="details-name edit-field capitalize"
                    placeholder={studentInfo.company
                        ? new Date(studentInfo.company.resumptionDate).toLocaleDateString()
                        : "N/A"}
                    onChange={(e) => setCompany({...company, resumptionDate : e.target.value})}
                    />
                  </div>
                  <div className="details-content">
                    <p className="details-title">
                      Termination Date(MM/DD/YYYY)
                    </p>
                    <input 
                    type="date" 
                    id="term-date"
                    name="term-date"
                    className="details-name edit-field capitalize"
                    placeholder={studentInfo.company
                        ? new Date(studentInfo.company.expectedEndDate).toLocaleDateString()
                        : "N/A"}
                    onChange={(e) => setCompany({...company, expectedEndDate : e.target.value})}
                    />
                  </div>
                </div>
                
              </div>
                <div className={updateFail && !success ? "post-fail" : ""}>
                  {updateFail && !success ? (
                    <div className="result-icon">
                      {errMessage}
                    </div>
                  ) : (
                    ""
                  )}
                </div>
                <div className={success && !updateFail ? "post-success" : ""}>
                  {success && !updateFail ? (
                    <div className="result-icon">
                      {successMsg}
                    </div>
                  ) : (
                    ""
                  )}
                </div>
              <div className="reg-block cntr">
                <button
                  className="edit-btn"
                  type="submit"
                  onClick={handleSubmit}
                >
                  {loadingData ? (

                    <div className="center-load">
                      <span className="basic"></span>
                    </div>
                  ) : (
                    `Update`
                  )}
                </button>
              </div>
                </form>
              
            </div>
          )}
        </div>
      </div>
    </div>
  );
};

export default StudentDetails;
