import React from "react";
import { Link } from "react-router-dom";
import '../../CSS/Profile.css'

const StudentList = ({ studentData }) => {  
  return (
    <>
      {studentData.map((student) => {
        return (
          <tr key={student._id}>
            <td className="column6 capitalize">
              <Link
                to={`/coordinator/student/${student._id}`}
                className="reg"
              >{`${student.lastName} ${student.firstName} ${
                student.middleName ? student.middleName : ""
              }`}</Link>
            </td>
            <td className="column4">{student.matricNo}</td>
            <td className="column4 capitalize">{student.course}</td>
            <td className="column4">{student.email}</td>
            <td className="column4">{student.phone}</td>
            <td className="column4">{student.level}</td>
            <td className="column4 capitalize">{student.bankDetails.name}</td>
            <td className="column4">{student.bankDetails.accountNumber}</td>
            <td className="column4">{student.bankDetails.sortCode}</td>
            <Link to={`/coordinator/edit/student/${student._id}`}>
            <button className="edit-btn">Edit</button>
            </Link>
          </tr>
        );
      })}
    </>
  );
};

export default StudentList;
