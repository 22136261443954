import React, { useEffect, useState } from "react";
import LecturerNav from "../../Components/LecturerNav";
import "../../CSS/Profile.css";
import "../../CSS/Table.css";
import axios from "../../api/axios";

const GradeDefence = () => {
  useEffect(() => {
    document.title = "Grade Defence";
  }, []);

  const [loading, setLoading] = useState(true);
  const [submitLoading, setSubmitLoading] = useState(false);
  const [loadFail, setLoadFail] = useState(false);
  const [errMessage, setErrMessage] = useState();
  const [studentData, setStudentData] = useState();
  const [studentCode, setStudentCode] = useState();
  const [success, setSucess] = useState(false);
  const [regFail, setRegFail] = useState(false);
  const [score, setScore] = useState();
  const [studentId, setStudentId] = useState();
  const type = "defense";

  useEffect(() => {
    setLoadFail(false);
    const fetchData = async () => {
      try {
        const res = await axios.get(
          `/api/supervisor/assignedStudentsForDefense`
        );
        setStudentData(res.data);
        setLoading(false);
      } catch (error) {
        setLoadFail(true);
        setLoading(false);
        setErrMessage(error.response.data.message);
      }
    };
    fetchData();
  }, []);

  const handleSubmit = async (e) => {
    e.preventDefault();
    setSubmitLoading(true);
    setRegFail(false);
    try {
      const res = await axios.post(
        `/api/supervisor/assignGrade`,
        JSON.stringify({ studentId, type, score }),
        {
          headers: { "Content-type": "application/json" }
        }
      );
      setSucess(true);
      setSubmitLoading(false);
      setRegFail(false);
      setTimeout(() => {
        setSucess(false);
      }, 3000);
    } catch (err) {
      console.log(err);
      setRegFail(true);
      setSubmitLoading(false);
      err.response.data.errors
        ? setErrMessage(err.response.data.errors)
        : setErrMessage(err.response.data.message);
    }
  };

  return (
    <div>
      <LecturerNav />
      <div className="page-wrapper">
        <div className="page-content">
          <div className="heading">
            <h2 className="profile-header">Grade Defence</h2>
          </div>
          {loading ? (
            <div className="center-load">
              <span className="basic big"></span>
            </div>
          ) : loadFail ? (
            <h2 className="profile-header">{errMessage}</h2>
          ) : (
            <div className="loaded">
              <div className="limiter">
                <div className="container-table100">
                  <div className="wrap-table100">
                    <div className="table100">
                      <table className="inspection-table grade def">
                        <thead>
                          <tr className="table100-head">
                            <th className="column3">Student Name</th>
                            <th className="column3">Matric Number</th>
                            <th className="column5">Course</th>
                            <th className="column3">Defence Grade</th>
                            <th className="column3">Assign Grade</th>
                          </tr>
                        </thead>
                        <tbody>
                          {studentData.map((student) => {
                            return (
                              <tr key={student._id}>
                                <td className="column5 capitalize">
                                  {student.studentDetails.name}
                                </td>
                                <td className="column3">
                                  {student.studentDetails.matricNo}
                                </td>
                                <td className="column3 capitalize">
                                  {student.studentDetails.course}
                                </td>

                                <td className="column3">
                                  <input
                                    type="text"
                                    name="deadline"
                                    id="deadline"
                                    className="report-date2"
                                    onChange={(e) => {
                                      setStudentId(student.studentDetails._id);
                                      setStudentCode(
                                        student.studentDetails.studentCode
                                      );
                                      setScore(e.target.value);
                                    }}
                                    placeholder={student?.grade?.inspectionScore || ''}
                                  />
                                </td>
                                <td className="column3">
                                  <button
                                    className="submit-upload"
                                    onClick={handleSubmit}
                                  >
                                    {student.studentDetails.studentCode ===
                                      studentCode && submitLoading ? (
                                      <div className="center-load">
                                        <span className="basic"></span>
                                      </div>
                                    ) : (
                                      `Assign`
                                    )}
                                  </button>
                                  <div
                                    className={
                                      student.studentDetails.studentCode ===
                                        studentCode && regFail
                                        ? "post-fail"
                                        : ""
                                    }
                                  >
                                    {student.studentDetails.studentCode ===
                                      studentCode && regFail ? (
                                      <div className="result-icon">
                                        {errMessage}
                                      </div>
                                    ) : (
                                      ""
                                    )}
                                  </div>
                                  <div
                                    className={
                                      student.studentDetails.studentCode ===
                                        studentCode && success
                                        ? "post-success"
                                        : ""
                                    }
                                  >
                                    {student.studentDetails.studentCode ===
                                      studentCode && success ? (
                                      <div className="result-icon">
                                        Graded Successfully!
                                      </div>
                                    ) : (
                                      ""
                                    )}
                                  </div>
                                </td>
                              </tr>
                            );
                          })}
                        </tbody>
                      </table>
                    </div>
                  </div>
                </div>
              </div>
            </div>
          )}
        </div>
      </div>
    </div>
  );
};

export default GradeDefence;
