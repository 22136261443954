import React, { useEffect, useState } from "react";
import axios from "../../api/axios";
import Pagination from "../../Pagination";
import csvIcon from "../../Icons/filetype-csv.svg";
import CoordinatorNav from "../../Components/CoordinatorNav";
import StudentList from "./StudentList";
import "../../CSS/Profile.css";

const Students = () => {
  useEffect(() => {
    document.title = "Registered Students";
  }, []);

  const [loading, setLoading] = useState(true);
  const [loadFail, setLoadFail] = useState(false);
  const [errMessage, setErrMessage] = useState();
  const [currentPage, setCurrentPage] = useState(1);
  const [studentsPerPage, setStudentsPerPage] = useState(10);
  const [noOfStudents, setNoOfStudents] = useState(0);
  const [currentStudents, setCurrentStudents] = useState([]);
  const [searchTerm, setSearchTerm] = useState("");
  const currentDomain = process.env.REACT_APP_BASE_URL;
  const getPageData = async function (page) {
    try {
      setLoading(true);

      const res = await axios.get(
        `/api/coordinators/students?limit=${studentsPerPage}&page=${page}`
      );

      setNoOfStudents(res.data.totalStudents)
      setCurrentStudents(res.data.students);
      setLoading(false);
    } catch (error) {
      setLoadFail(true);
      setLoading(false);
      setErrMessage(error.response.data.message);
    }
  };

  const search = async () => {
    try {
      if (searchTerm.length < 3) {
        return;
      }
      
      setLoading(true);
      const res = await axios.get(
        `/api/coordinators/search/students?q=${searchTerm}`
      );

      setNoOfStudents(res.data.length)
      setStudentsPerPage(res.data.length); 
      setCurrentStudents(res.data);
      setLoading(false);
    } catch (error) {
      setLoadFail(true);
      setLoading(false);
      setErrMessage(error.response.data.message);
    }
  };

  useEffect(() => {
    setLoadFail(false);
    const fetchData = async () => {
      try {
        const res = await axios.get(`/api/coordinators/students?limit=${studentsPerPage}`);

        setNoOfStudents(res.data.totalStudents)
        setCurrentStudents(res.data.students);
        setLoading(false);
      } catch (error) {
        setLoadFail(true);
        setLoading(false);
        setErrMessage(error.response.data.message);
      }
    };
    fetchData();
  }, []);

  return (
    <div>
      <CoordinatorNav />
      <div className="page-wrapper">
        <div className="page-content">
          <div className="heading">
            <h2 className="profile-header">Registered Students</h2>
          </div>
          {loading ? (
            <div className="center-load">
              <span className="basic big"></span>
            </div>
          ) : loadFail ? (
            <h2 className="profile-header">{errMessage}</h2>
          ) : (
            <div className="limiter loaded">
              <a className="search-btn export-btn" href={currentDomain + "/api/coordinators/students/download"} download>
                <img src={csvIcon} alt="csv" />
                Export
              </a>
              <div className="container-table100">
                <div className="wrap-table100">
                  <div className="table100">
                    <div  className="search-box">
                      <input
                        type="text"
                        name="search"
                        id="search"
                        className="login-form-input search-input"
                        onChange={(e) => setSearchTerm(e.target.value)}
                        value={searchTerm}
                      />
                      <button 
                        className="search-btn"
                        onClick={search}>Search</button>
                      <button 
                        className="search-btn"
                        onClick={() => window.location.reload()}>Reset</button>
                    </div>
                    <table className="co-students">
                      <thead>
                        <tr className="table100-head">
                          <th className="column4">Student Name</th>
                          <th className="column4">Matric Number</th>
                          <th className="column4">Course</th>
                          <th className="column4">Email</th>
                          <th className="column4">Phone Number</th>
                          <th className="column4">Level</th>
                          <th className="column4">Bank Name</th>
                          <th className="column4">Bank Account Number</th>
                          <th className="column4">Bank Sort Code</th>
                        </tr>
                      </thead>
                      <tbody>
                        <StudentList studentData={currentStudents} />
                      </tbody>
                    </table>
                    <div className="pagination-container">
                      <Pagination
                        totalPosts={noOfStudents}
                        postsPerPage={studentsPerPage}
                        setCurrentPage={setCurrentPage}
                        currentPage={currentPage}
                        getPageData={getPageData}
                      />
                    </div>
                  </div>
                </div>
              </div>
            </div>
          )}
        </div>
      </div>
    </div>
  );
};

export default Students;
