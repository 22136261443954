export const Reducer = (state, action) => {
  switch (action.type) {
    case "LOGIN_START":
      return {
        student: null,
        isFetching: true,
        error: false
      };
    case "LOGIN_SUCCESS":
      return {
        student: action.payload,
        isFetching: false,
        error: false
      };
    case "LOGIN_FAILURE":
      return {
        student: null,
        isFetching: false,
        error: true
      };
    case "LOGOUT":
      return {
        student: null,
        isFetching: false,
        error: false
      };
    default:
      return state;
  }
};

export const ReducerCoordinator = (state, action) => {
  switch (action.type) {
    case "LOGIN_START_COORDINATOR":
      return {
        coordinator: null,
        isFetching: true,
        error: false
      };
    case "LOGIN_SUCCESS_COORDINATOR":
      return {
        coordinator: action.payload,
        isFetching: false,
        error: false
      };
    case "LOGIN_FAILURE_COORDINATOR":
      return {
        coordinator: null,
        isFetching: false,
        error: true
      };
    case "LOGOUT_COORDINATOR":
      return {
        coordinator: null,
        isFetching: false,
        error: false
      };
    default:
      return state;
  }
};

export const ReducerSupervisor = (state, action) => {
  switch (action.type) {
    case "LOGIN_START_SUPERVISOR":
      return {
        supervisor: null,
        isFetching: true,
        error: false
      };
    case "LOGIN_SUCCESS_SUPERVISOR":
      return {
        supervisor: action.payload,
        isFetching: false,
        error: false
      };
    case "LOGIN_FAILURE_SUPERVISOR":
      return {
        supervisor: null,
        isFetching: false,
        error: true
      };
    case "LOGOUT_SUPERVISOR":
      return {
        supervisor: null,
        isFetching: false,
        error: false
      };
    default:
      return state;
  }
};


export const ReducerVerification = (state, action) => {
  switch (action.type) {
    case "VERIFICATION_START":
      return {
        verifiction: null,
        isFetching: true,
        error: false
      };
    case "VERIFICATION_SUCCESS":
      return {
        verification: action.payload,
        isFetching: false,
        error: false
      };
    case "VERIFICATION_FAILURE":
      return {
        verification: null,
        isFetching: false,
        error: true
      };
    default:
      return state;
  }
};


// export const ReducerValidation = (state, action) => {
//   switch (action.type) {
//     case "VALIDATION_START":
//       return {
//         validation: null,
//         isFetching: true,
//         error: false
//       };
//     case "VALIDATION_SUCCESS":
//       return {
//         validation: action.payload,
//         isFetching: false,
//         error: false
//       };
//     case "VALIDATION_FAILURE":
//       return {
//         validation: null,
//         isFetching: false,
//         error: true
//       };
//     default:
//       return state;
//   }
// };
