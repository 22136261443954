import React, { useEffect, useState } from "react";
import ProfileNav from "../../Components/ProfileNav";
import axios from "../../api/axios";
import "../../CSS/Profile.css";

const Index = () => {
  const [studentInfo, setStudentInfo] = useState();
  const [company, setCompany] = useState();
  const [loading, setLoading] = useState(true);
  const [errMessage, setErrMessage] = useState();
  const [loadFail, setLoadFail] = useState(false);

  useEffect(() => {
    document.title = "Student's Home";
  }, []);

  useEffect(() => {
    setLoadFail(false);
    const fetchData = async () => {
      try {
        const res = await axios.get(`/api/student/getDetails`);
        setStudentInfo(res.data.data);
        setCompany(res.data.data.company);
        setLoading(false);
      } catch (error) {
        setLoadFail(true);
        setLoading(false);
        setErrMessage(error.response.data.message);
      }
    };
    fetchData();
  }, []);

  return (
    <div>
      <ProfileNav />
      <div className="page-wrapper">
        <div className="page-content">
          {loading ? (
            <div className="center-load">
              <span className="basic big"></span>
            </div>
          ) : loadFail ? (
            <h2>{errMessage}</h2>
          ) : (
            <div className="loaded">
              <div className="heading">
                <h2 className="profile-header capitalize">
                  Welcome {studentInfo ? studentInfo.name.split(" ")[0] : ""}!
                  Here's Your Dashboard
                </h2>
              </div>

              <div className="dashboard-details">
                <div className="details-half">
                  <h2 className="details-heading">School Details</h2>

                  <div className="details-content">
                    <p className="details-title">Name</p>
                    <p className="details-name capitalize">{studentInfo.name}</p>
                  </div>

                  <div className="details-content">
                    <p className="details-title">Student Code</p>
                    <p className="details-name">{studentInfo.studentCode}</p>
                  </div>

                  <div className="details-content">
                    <p className="details-title">Matric Number</p>
                    <p className="details-name">{studentInfo.matricNo}</p>
                  </div>

                  <div className="details-content">
                    <p className="details-title">Level | Sex</p>
                    <p className="details-name">
                      {studentInfo.level} | {studentInfo.sex}
                    </p>
                  </div>

                  <div className="details-content">
                    <p className="details-title">Course</p>
                    <p className="details-name capitalize">{studentInfo.course}</p>
                  </div>

                  <div className="details-content">
                    <p className="details-title">Email</p>
                    <p className="details-name">{studentInfo.email}</p>
                  </div>

                  <div className="details-content">
                    <p className="details-title">Phone Number</p>
                    <p className="details-name">{studentInfo.phone}</p>
                  </div>

                  <div className="details-content">
                    <p className="details-title">
                      Edit Work Details Before(DD/MM/YYYY)
                    </p>
                    <p className="details-name">
                      {studentInfo.defence ? studentInfo.defence : "N/A"}
                    </p>
                  </div>
                  {studentInfo.defense_supervisor ? (
                    <>
                      <div className="details-content">
                        <p className="details-title">Defence Supervisor</p>
                        <p className="details-name capitalize">
                          {studentInfo.defense_supervisor.firstName}{" "}
                          {studentInfo.defense_supervisor.lastName}
                        </p>
                      </div>
                      <div className="details-content">
                        <p className="details-title">Defence Supervisor's Number</p>
                        <p className="details-name">
                          {studentInfo.defense_supervisor.phone}
                        </p>
                      </div>
                      <div className="details-content">
                        <p className="details-title">Defence Supervisor's Email</p>
                        <p className="details-name">
                          {studentInfo.defense_supervisor.email}
                        </p>
                      </div>
                      <div className="details-content">
                        <p className="details-title">Defence Date & Time</p>
                        <p className="details-name">N/A</p>
                      </div>
                      <div className="details-content">
                        <p className="details-title">Defence Venue</p>
                        <p className="details-name capitalize">
                          {studentInfo.defense_supervisor.office
                            ? studentInfo.defense_supervisor.office
                            : "N/A"}
                        </p>
                      </div>
                    </>
                  ) : (
                    <>
                      <div className="details-content">
                        <p className="details-title">Defence Supervisor</p>
                        <p className="details-name">N/A</p>
                      </div>
                      <div className="details-content">
                        <p className="details-title">Defence Supervisor's Number</p>
                        <p className="details-name">N/A</p>
                      </div>
                      <div className="details-content">
                        <p className="details-title">Defence Supervisor's Email</p>
                        <p className="details-name">N/A</p>
                      </div>
                      <div className="details-content">
                        <p className="details-title">Defence Date & Time</p>
                        <p className="details-name">N/A</p>
                      </div>
                      <div className="details-content">
                        <p className="details-title">Defence Venue</p>
                        <p className="details-name capitalize">N/A</p>
                      </div>
                    </>
                  )}
                </div>

                <div className="details-half">
                  <h2 className="details-heading">Work Details</h2>
                  <div className="details-content">
                    <p className="details-title">Company Name</p>
                    <p className="details-name capitalize">
                      {company ? company.name : "N/A"}
                    </p>
                  </div>
                  <div className="details-content">
                    <p className="details-title">Full company address</p>
                    <p className="details-name capitalize">
                      {company ? company.address : "N/A"}
                    </p>
                  </div>
                  <div className="details-content">
                    <p className="details-title">State | LGA</p>
                    <p className="details-name capitalize">
                      {company ? company.state : "N/A"} |
                      {company ? company.LGA : "N/A"}
                    </p>
                  </div>
                  <div className="details-content">
                    <p className="details-title">Office Email Address</p>
                    <p className="details-name">
                      {company ? company.email : "N/A"}
                    </p>
                  </div>
                  <div className="details-content">
                    <p className="details-title">Office Phone Number</p>
                    <p className="details-name">
                      {company ? company.phone : "N/A"}
                    </p>
                  </div>
                  <div className="details-content">
                    <p className="details-title">Assigned Department</p>
                    <p className="details-name capitalize">
                      {company ? company.assignedDepartment : "N/A"}
                    </p>
                  </div>
                  <div className="details-content">
                    <p className="details-title">Job Description</p>
                    <p className="details-name capitalize2">
                      {company ? company.jobDescription : "N/A"}
                    </p>
                  </div>
                  <div className="details-content">
                    <p className="details-title">Resumption Date(MM/DD/YYYY)</p>
                    <p className="details-name">
                      {company
                        ? new Date(company.resumptionDate).toLocaleDateString()
                        : "N/A"}
                    </p>
                  </div>
                  <div className="details-content">
                    <p className="details-title">Termination Date(MM/DD/YYYY)</p>
                    <p className="details-name">
                      {company
                        ? new Date(company.expectedEndDate).toLocaleDateString()
                        : "N/A"}
                    </p>
                  </div>
                  {studentInfo.inspection_supervisor ? (
                    <>
                      <div className="details-content">
                        <p className="details-title">Inspection Supervisor</p>
                        <p className="details-name capitalize">
                          {studentInfo.inspection_supervisor.firstName}{" "}
                          {studentInfo.inspection_supervisor.lastName}
                        </p>
                      </div>
                      <div className="details-content">
                        <p className="details-title">Inspector's Number</p>
                        <p className="details-name">
                          {studentInfo.inspection_supervisor.phone}
                        </p>
                      </div>
                      <div className="details-content">
                        <p className="details-title">Inspector's Email</p>
                        <p className="details-name">
                          {studentInfo.inspection_supervisor.email}
                        </p>
                      </div>
                      <div className="details-content">
                        <p className="details-title">Inspection Date & Time</p>
                        <p className="details-name capitalize">{studentInfo.inspection_supervisor.assignedDate ? new Date(studentInfo.inspection_supervisor.assignedDate).toLocaleDateString() :'N/A'}</p>
                      </div>
                    </>
                  ) : (
                    <>
                      <div className="details-content">
                        <p className="details-title">Inspection Supervisor</p>
                        <p className="details-name capitalize">N/A</p>
                      </div>
                      <div className="details-content">
                        <p className="details-title">Inspector's Number</p>
                        <p className="details-name">N/A</p>
                      </div>
                      <div className="details-content">
                        <p className="details-title">Inspector's Email</p>
                        <p className="details-name">N/A</p>
                      </div>
                      <div className="details-content">
                        <p className="details-title">Inspection Date & Time</p>
                        <p className="details-name capitalize">N/A</p>
                      </div>
                    </>
                  )}
                </div>
              </div>
            </div>
          )}

          {/* <div className="footer-container">
          <hr className="footer--line" />
          <footer>
            Copyright © 2022 Babcock University. All Rights Reserved
          </footer>
        </div> */}
        </div>
      </div>
    </div>
  );
};

export default Index;
