import React, { useEffect, useState } from "react";
import { useNavigate } from "react-router-dom";
import ProfileNav from "../../Components/ProfileNav";
import axios from "../../api/axios";
import "../../CSS/Profile.css";
import excl from "../../Icons/exclamation-lg.svg";
import tick from "../../Icons/check-lg.svg";

const Report = () => {
  useEffect(() => {
    document.title = "Weekly Report Upload";
  }, []);

  const [monday, setMonday] = useState();
  const [tuesday, setTuesday] = useState();
  const [wednesday, setWednesday] = useState();
  const [thursday, setThursday] = useState();
  const [friday, setFriday] = useState();
  const [success, setSucess] = useState(false);
  const [loading, setLoading] = useState(false);
  const [errMessage, setErrMessage] = useState();
  const [uploadFail, setUploadFail] = useState();

  const navigate = useNavigate();

  const handleSubmit = async (e) => {
    e.preventDefault();
    setUploadFail(false);
    setLoading(true);
    try {
      const res = await axios.post(
        `/api/student/weeklyReports`,
        JSON.stringify({
          monday,
          tuesday,
          wednesday,
          thursday,
          friday
        }),
        {
          headers: { "Content-type": "application/json" }
        }
      );
      setSucess(true);
      setLoading(false);
      setUploadFail(false);
      setTimeout(() => {
        navigate("/students/index");
      }, 2500);
    } catch (err) {
      setLoading(false);
      setUploadFail(true);
      err.response.data.errors
        ? setErrMessage(err.response.data.errors)
        : setErrMessage(err.response.data.message);
    }
  };

  return (
    <div>
      <ProfileNav />
      <div className="page-wrapper">
        <div className="page-content">
          <div className="heading">
            <h2 className="profile-header">Weekly Report Upload</h2>
            <h2 className="details-heading">Upload Your Reports Everyday</h2>
            <h2 className="details-heading">
              Uploading Reports for days ahead will not get recorded
            </h2>
          </div>

          <div className="work-form-wrapper">
            <form className="report-form">
              <div className="date-input">
                <label htmlFor="monday">Monday</label>
              </div>
              <textarea
                name="monday-text"
                id="monday-text"
                cols="50"
                rows="5"
                required
                className="report-text"
                onChange={(e) => setMonday(e.target.value)}
              ></textarea>

              <div className="date-input">
                <label htmlFor="tuesday">Tuesday</label>
              </div>
              <textarea
                name="tuesday-text"
                id="tuesday-text"
                cols="30"
                rows="5"
                required
                className="report-text"
                onChange={(e) => setTuesday(e.target.value)}
              ></textarea>

              <div className="date-input">
                <label htmlFor="wednesday">Wednesday</label>
              </div>
              <textarea
                name="wednesday-text"
                id="wednesday-text"
                cols="30"
                rows="5"
                required
                className="report-text"
                onChange={(e) => setWednesday(e.target.value)}
              ></textarea>

              <div className="date-input">
                <label htmlFor="thursday">Thursday</label>
              </div>
              <textarea
                name="thursday-text"
                id="thursday-text"
                cols="30"
                rows="5"
                required
                className="report-text"
                onChange={(e) => setThursday(e.target.value)}
              ></textarea>

              <div className="date-input">
                <label htmlFor="friday">Friday</label>
              </div>
              <textarea
                name="friday-text"
                id="friday-text"
                cols="30"
                rows="5"
                required
                className="report-text"
                onChange={(e) => setFriday(e.target.value)}
              ></textarea>
              <button className="submit-upload" onClick={handleSubmit}>
                {loading ? (
                  <div className="center-load">
                    <span className="basic"></span>
                  </div>
                ) : (
                  `Upload Report`
                )}
              </button>
              <div className={uploadFail ? "post-fail" : ""}>
                {uploadFail ? (
                  <div className="result-icon">
                    <img src={excl} />
                    {errMessage}
                  </div>
                ) : (
                  ""
                )}
              </div>
              <div className={success ? "post-success" : ""}>
                {success ? (
                  <div className="result-icon">
                    <img src={tick} />
                    Report Upload Successful!
                  </div>
                ) : (
                  ""
                )}
              </div>
            </form>
          </div>
        </div>
      </div>
    </div>
  );
};

export default Report;
